import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { filter } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { Login } from 'src/app/util/login';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';

interface Alarm {
  id: number,
  date: Date,
  alarmTime: string,
  name: string
}

@Component({
  selector: 'app-profile-alarms',
  templateUrl: './profile-alarms.component.html',
  styleUrls: ['./profile-alarms.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
})
export class ProfileAlarmsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

  alarmColumns: string[] = ['date', 'keyword', 'action'];

  alarm: MatTableDataSource<Alarm>;

  alarmListLoading: boolean = true;

  searchFilter: string = '';

  baseURL = getHostUrl();

  constructor(private _http: HttpClient,
    public _login: Login,
    private _config: ConfigModule,
    private _bottomSheet: MatBottomSheet) {
  }

  ngOnInit(): void {
    this.load();
    this.alarm = new MatTableDataSource<any>();
    this.alarm.paginator = this.paginator;
    this.alarm.sortingDataAccessor = (item: Alarm, property) => {
      switch (property) {
        case 'date': const date = DateTime.fromJSDate(item.date).setZone('Europe/Berlin');
          const time = DateTime.fromFormat(item.alarmTime, 'HH:mm:ss').setZone('Europe/Berlin'); return date.set({ hour: time.hour, minute: time.minute }).setLocale('de').toJSDate(); default: return item[property];
      }
    };
    this.alarm.sort = this.sort;
    this._config.setTitle('Einsatzbestätigungen');
  }

  getFormattedDateTime(_date: Date, _time: string) {
    const date = DateTime.fromJSDate(_date).setZone('Europe/Berlin');
    const time = DateTime.fromSQL(_time).setZone('Europe/Berlin');
    return date.set({ hour: time.hour, minute: time.minute }).setLocale('de').toFormat('dd.MM.yyyy HH:mm');
  }

  async send(element) {
    const { value: email } = await Swal.fire({
      title: 'Empfänger E-Mail eingeben',
      input: 'email',
      inputPlaceholder: 'email@test.de',
      validationMessage: 'E-Mail Adresse ist ungültig!',
    });

    if (email) {
      this.alarmListLoading = true;
      this._http.get(`${getHostUrl()}profile/alarms/confirmation/send/${element.id}/${email}`).subscribe(result => {
        this._config.showSnackbar('E-Mail wurde erfolgreich versendet!', 3000, 'success');
        this.alarmListLoading = false;
      }, error => {
        this.alarmListLoading = false;
        this._config.showSnackbar('Konnte Bestätigungen nicht versenden aufgrund eines internen Serverfehlers!', 3000, 'error');
      });
    }
  }

  load() {
    this._http.get<Alarm[]>(`${getHostUrl()}profile/alarms`).subscribe(result => {
      this.alarm.data = result.map(alarm => { alarm.date = new Date(alarm.date); return alarm; });
      this.alarm.paginator = this.paginator;
      this.alarm.sort = this.sort;
      this.alarmListLoading = false;
      this.alarm.filterPredicate = this.filter();
    }, error => {
      this._config.showSnackbar('Konnte Einsatzliste nicht laden!', 3000, 'error');
      this.alarmListLoading = false;
    });
  }

  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.alarm.filter = this.searchFilter;
  }

  filter(): (data: Alarm, searchTerms: string) => boolean {
    let filterFunction = function (data: Alarm, searchTerms: string): boolean {
      let searchResult1 = false;

      if (searchTerms) {
        Object.keys(data).forEach(elem => {
          if (String(data[elem]).toLowerCase().includes(searchTerms.toLowerCase())) {
            searchResult1 = true;
          } else if (data[elem] instanceof Date) {
            const date = DateTime.fromJSDate(data[elem]);
            searchResult1 = date.toFormat('dd.MM.yyyy').includes(searchTerms.toLowerCase());
          }
          if (searchResult1)
            return;
        });
      } else {
        searchResult1 = true;
      }
      return searchResult1;
    };
    return filterFunction;
  }

  openMenu() {
    const menuRef = this._bottomSheet.open(this.menu, { disableClose: true });
    menuRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => menuRef.dismiss());
  }
}
