<div class="clearfix mt-3">
  <div class="row">
    <div class="col-12 col-md-6">
      <h2>Monatsansicht</h2>
    </div>
    <div class="col-md-1 col-1">
      <mat-spinner diameter="30" *ngIf="showLoader" class="float-start mt-3 me-3"></mat-spinner>
    </div>
    <div class="col-md-5 col-11">
      <mat-form-field appearance="outline" class="input-full-width">
        <mat-label>Monat</mat-label>
        <input matInput [matDatepicker]="picker" class="text-center" readonly="" (click)="picker.open()" [max]="max"
          [formControl]="currentMonth" />
        <mat-datepicker #picker startView="multi-year" (monthSelected)="onMonthSelected($event)"></mat-datepicker>
        <button mat-icon-button matPrefix (click)="previousDate()">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-icon-button matSuffix (click)="nextDate()"
          [disabled]="dayjs(currentMonth.value).isSame(dayjs(), 'd')">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="table-responsive">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Datum</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.date">
          {{dayjs(element.date).format('DD.MM.YYYY')}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef>Start Zeit</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.start">
          {{dayjs(element.start, 'HH:mm:ss').format('HH:mm')}} Uhr
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="end">
      <th mat-header-cell *matHeaderCellDef>End Zeit</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.end">
          {{dayjs(element.end, 'HH:mm:ss').format('HH:mm')}} Uhr
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef> Gesamt </th>
      <td mat-cell *matCellDef="let element">
        <span [class.text-bold]="!element.id">{{dayjs(element.total, 'HH:mm:ss').format('HH:mm')}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button matTooltip="Zu diesem Tag springen" (click)="jumpToDay(element.date)" *ngIf="element.date">
          <mat-icon>history</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>