<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Zeiterfassung Administration</h3>

    <mat-card>
      <mat-card-content>
        <a routerLink="/timetracking" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab class="pageNext" color="primary">
          <mat-icon>apps</mat-icon>
        </a>

        <div class="clearfix mb-3">
          <div class="float-end">
            <mat-slide-toggle (change)="changeListView($event)">Nur unbestätigte Buchungen anzeigen</mat-slide-toggle>
          </div>
        </div>

        <mat-form-field appearance="outline" class="input-full-width">
          <mat-label>Suchen</mat-label>
          <input matInput [formControl]="tableSearch" />
        </mat-form-field>

        <div class="table-responsive">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="category">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Kategorie</th>
              <td mat-cell *matCellDef="let element" [matTooltip]="'Gruppe: ' + element.group"> {{element.category}} </td>
            </ng-container>

            <ng-container matColumnDef="comment">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Kommentar</th>
              <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
            </ng-container>

            <ng-container matColumnDef="timeframe">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Zeitraum</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.startTime">
                  {{dayjs(element.startTime, 'HH:mm:ss').format('HH:mm')}} -
                  {{dayjs(element.endTime, 'HH:mm:ss').format('HH:mm')}} Uhr
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Datum</th>
              <td mat-cell *matCellDef="let element">
                {{dayjs(element.date).format('DD.MM.YYYY')}}
              </td>
            </ng-container>

            <ng-container matColumnDef="user">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Gebucht von </th>
              <td mat-cell *matCellDef="let element">
                <span>{{ element.firstname }} {{ element.lastname }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element">
                <mat-icon matTooltip="Buchung bestätigt" *ngIf="element.verified == 1">check_circle</mat-icon>
                <mat-icon matTooltip="Buchung noch nicht bestätigt" color="warn"
                  *ngIf="element.verified == 0">pending</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="element.id">
                  <mat-icon>menu</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <ng-template matMenuContent>
                    <button mat-menu-item (click)="verifyEntry(element.id)" *ngIf="element.verified == 0">
                      Buchung bestätigen
                      <mat-icon>done_outline</mat-icon>
                    </button>
                    <button mat-menu-item (click)="deleteEntry(element.id)">
                      Buchung löschen
                      <mat-icon color="warn">delete</mat-icon>
                    </button>
                    <button mat-menu-item (click)="editEntry(element)" *ngIf="element.verified == 0">
                      Buchung bearbeiten
                      <mat-icon>edit</mat-icon>
                    </button>
                  </ng-template>
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>

        <div class="mt-4 row" *ngIf="showLoader">
          <div class="col-2">
            <ngx-skeleton-loader count="3" [theme]="{ 
            height: '40px'
          }"></ngx-skeleton-loader>
          </div>
          <div class="col-2">
            <ngx-skeleton-loader count="3" [theme]="{ 
            height: '40px'
          }"></ngx-skeleton-loader>
          </div>
          <div class="col-2">
            <ngx-skeleton-loader count="3" [theme]="{ 
              height: '40px'
            }"></ngx-skeleton-loader>
          </div>
          <div class="col-2">
            <ngx-skeleton-loader count="3" [theme]="{ 
              height: '40px'
            }"></ngx-skeleton-loader>
          </div>
          <div class="col-2">
            <ngx-skeleton-loader count="3" [theme]="{ 
              height: '40px'
            }"></ngx-skeleton-loader>
          </div>
          <div class="col-2">
            <ngx-skeleton-loader count="3" [theme]="{ 
              height: '40px'
            }"></ngx-skeleton-loader>
          </div>
        </div>

        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #timeAdminMenu>
  <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
    <mat-icon>close</mat-icon>
  </a>
  <mat-nav-list class="mt-5">
    <mat-list-item routerLink="/admin/timetracking/categories" (click)="_bottomSheet.dismiss()">
      <h3 matListItemLine>Kategorien verwalten</h3>
      <div matListItemMeta>
        <mat-icon>category</mat-icon>
      </div>
    </mat-list-item>
  </mat-nav-list>
</ng-template>
