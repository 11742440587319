<div class="container">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Materialien</h3>
    <mat-card>
      <mat-card-content>
        <a routerLink="../" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext">
          <mat-icon>apps</mat-icon>
        </a>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput [formControl]="filterCtrl" placeholder="Suche">
              <button mat-icon-button matSuffix matTooltip="QR Code Scanner" (click)="openScanner()">
                <mat-icon>qr_code_scanner</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-lg-12 col-12">
            <div class="table-responsive">
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 input-full-width"
                matSortActive="name" matSortDirection="asc" matSortDisableClear>
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                  <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                    {{element.name}}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="serialNumber">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Seriennummer</th>
                  <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                    {{element.serialNumber}}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="category">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Kategorie</th>
                  <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                    {{element.category}}</td>
                </ng-container>

                <!-- Location Column -->
                <ng-container matColumnDef="location">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Lagerort</th>
                  <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                    <mat-icon *ngIf="element.firstname || element.lastname" class="me-1">person</mat-icon>
                    {{element.vehicle ?? element.location ?? element.storage ?? (element.firstname + ' ' +
                    element.lastname) ?? ''}}
                  </td>
                </ng-container>

                <!-- Check Column -->
                <ng-container matColumnDef="check">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Nächste Prüfung
                  </th>
                  <td mat-cell *matCellDef="let element" [routerLink]="['details', element.id]">
                    <span *ngIf="element.nextCycle" [class.text-danger]="element.nextCycle < 0"
                      [matTooltip]="dayjs(element.cycle).format('dddd, DD.MM.YYYY HH:mm') + ' Uhr'">
                      {{ dayjs(element.cycle).fromNow() }}
                    </span>
                  </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                  <td mat-cell *matCellDef="let element" routerLink="{{element.id}}">

                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
              </table>
            </div>
            <div class="mt-4 row" *ngIf="isLoading">
              <div class="col-6">
                <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
              </div>
              <div class="col-6">
                <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
              </div>
              <div class="col-12">
                <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
              </div>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #menu>
  <div class="clearfix">
    <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-nav-list class="mt-4">
    <mat-list-item routerLink="new" (click)="_bottomSheet.dismiss()">
      <mat-icon matListItemIcon>post_add</mat-icon>
      <a matListItemLine>Neues Material erstellen</a>
    </mat-list-item>
  </mat-nav-list>
</ng-template>