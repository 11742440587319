<div class="row">
  <div class="col-lg-8 col-12 offset-lg-2">
    <h3 class="section-header">Laufbahnereignisse</h3>
    <mat-card>
      <mat-card-content>
        <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a routerLink="new" mat-mini-fab class="pageNext" color="primary"
          *ngIf="_login.hasPermission('admin-course-edit')">
          <mat-icon>post_add</mat-icon>
        </a>
        <div class="row">
          <div class="col-lg-8 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Suche">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-6">
            <mat-form-field class="input-full-width" appearance="outline">
              <mat-label>Kategorie</mat-label>
              <mat-select multiple (selectionChange)="applyGroupFilter($event)" [(value)]="categoryFilter">
                <mat-option *ngFor="let category of categoryList"
                  [value]="category.id">{{category.category}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="table-responsive">
          <div *ngIf="courses.length===0">
            <mat-progress-bar mode="query"></mat-progress-bar>
          </div>
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" matSortActive="name"
            matSortDirection="asc" matSortDisableClear>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="vertical-align: middle;">Name
              </th>
              <td mat-cell *matCellDef="let element"
                [routerLink]="_login.hasPermission('admin-course-edit') ? ['details', element.id] : null">
                {{element.name}} </td>
            </ng-container>

            <!-- Location Column -->
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="vertical-align: middle;">Ort
              </th>
              <td mat-cell *matCellDef="let element"
                [routerLink]="_login.hasPermission('admin-course-edit') ? ['details', element.id] : null">
                {{element.location}} </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="vertical-align: middle;">

              </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="warn" matTooltip="Ereignis löschen" (click)="deleteCourse(element)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>