<mat-card>
    <mat-card-content>
        <div
            *ngIf="parent.guardEventsAll && parent.guardEventsAll.length > 0 && (parent.guardEventsAll.length!==(parent.guardEvents | async)?.length || all===false)"
            class="pageNext">
            <mat-slide-toggle (change)="allEventsChanged($event)" class="float-right" style="margin-right: 10px;">
            </mat-slide-toggle>
            <mat-label class="float-right" style="margin-right: 10px;">Alle: </mat-label>
        </div>
        <div class="alert alert-info" *ngIf="!parent.guardEventsAll || parent.guardEventsAll.length===0">
            Aktuell sind keine Sicherheitswachen verfügbar!
        </div>
        <div class="alert alert-info"
            *ngIf="!(!parent.guardEventsAll || parent.guardEventsAll.length===0) && (!parent.guardEvents || (parent.guardEvents | async)?.length===0)">
            Aktuell bist du keiner Sicherheitswache eingeteilt!
        </div>

        <div #eventWatcherList class="event" *ngFor="let event of parent.guardEvents | async" [attr.id]="'s' + event.id"
            [attr.ready]="checkScroll">
            <div class="overlayContainer">
                <div *ngIf="event.notiz === 'Veranstaltung entfällt' && !parent.env.mobile"
                    class="overlay overlayDesktop">
                    Veranstaltung entfällt</div>
                <div *ngIf="event.notiz === 'Veranstaltung entfällt' && parent.env.mobile"
                    class="overlay overlayMobile">
                    Veranstaltung<br />entfällt</div>
            </div>
            <h4>{{ event.name }}</h4>
            <b>Datum:</b> {{ event.date }} <br> <b>Beginn:</b> {{ event.time }} <br> <b>Veranstaltungort:</b>
            {{ event.location }}
            <span *ngIf="event.comment !== ''"><br> <b>Notiz:</b> {{ event.comment }}</span>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead style="text-align: center;">
                        <th style="width: 300px; border-width: 1px;"></th>
                        <th style="border-width: 1px;">Wachleiter</th>
                        <th style="border-width: 1px;">Wachmann</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="font-weight: bold;">Eingeteilt</td>
                            <td><a [routerLink]="['/user', event.guards[0]]"><span
                                        [innerHTML]="parent.getUser(event.guards[0])"></span></a></td>
                            <td><a [routerLink]="['/user', event.guards[3]]"><span
                                        [innerHTML]="parent.getUser(event.guards[3])"></span></a></td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold;">1. Reserve</td>
                            <td><a [routerLink]="['/user', event.guards[1]]"><span
                                        [innerHTML]="parent.getUser(event.guards[1])"></span></a></td>
                            <td><a [routerLink]="['/user', event.guards[4]]"><span
                                        [innerHTML]="parent.getUser(event.guards[4])"></span></a></td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold;">2. Reserve</td>
                            <td><a [routerLink]="['/user', event.guards[2]]"><span
                                        [innerHTML]="parent.getUser(event.guards[2])"></span></a></td>
                            <td><a [routerLink]="['/user', event.guards[5]]"><span
                                        [innerHTML]="parent.getUser(event.guards[5])"></span></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr />
        </div>
    </mat-card-content>
</mat-card>