<div class="clearfix mt-3">
  <div class="row">
    <div class="col-12 col-md-6">
      <h2>Tagesansicht</h2>
    </div>
    <div class="col-md-1 col-1">
      <mat-spinner diameter="30" *ngIf="showLoader" class="float-start mt-3 me-3"></mat-spinner>
    </div>
    <div class="col-md-5 col-11">
      <mat-form-field appearance="outline" class="input-full-width">
        <mat-label>Datum</mat-label>
        <input matInput [matDatepicker]="picker" class="text-center" readonly="" (click)="picker.open()" [max]="max"
          [formControl]="currentDate" />
        <mat-datepicker #picker></mat-datepicker>
        <button mat-icon-button matPrefix (click)="previousDate()">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-icon-button matSuffix (click)="nextDate()"
          [disabled]="dayjs(currentDate.value).isSame(dayjs(), 'd')">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="row text-center">
  <div class="col-6">
    <h2 class="time-display">
      <small>Kommen</small>
      <p>
        <b>{{ dayjs(startTime, 'HH:mm:ss').format('HH:mm')}}</b>
      </p>
    </h2>
  </div>
  <div class="col-6">
    <h2 class="time-display">
      <small>Gehen</small>
      <p>
        <b>{{ dayjs(endTime, 'HH:mm:ss').format('HH:mm')}}</b>
      </p>
    </h2>
  </div>
</div>

<div class="table-responsive">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>Kategorie</th>
      <td mat-cell *matCellDef="let element"> {{element.category}} </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>Kommentar</th>
      <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
    </ng-container>

    <ng-container matColumnDef="timeframe">
      <th mat-header-cell *matHeaderCellDef>Zeitraum</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.startTime">
          {{dayjs(element.startTime, 'HH:mm:ss').format('HH:mm')}} -
          {{dayjs(element.endTime, 'HH:mm:ss').format('HH:mm')}} Uhr
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef> Gesamt </th>
      <td mat-cell *matCellDef="let element">
        <span [class.text-bold]="!element.id">{{dayjs(element.total, 'HH:mm:ss').format('HH:mm')}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon matTooltip="Buchung bestätigt" *ngIf="element.verified == 1">check_circle</mat-icon>
        <mat-icon matTooltip="Buchung noch nicht bestätigt" color="warn"
          *ngIf="element.verified == 0">pending</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="element.id && element.verified === 0" >
          <mat-icon>menu</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <ng-template matMenuContent>
            <button mat-menu-item (click)="deleteEntry(element.id)" *ngIf="element.verified == 0">
              Buchung löschen
              <mat-icon color="warn">delete</mat-icon>
            </button>
            <button mat-menu-item (click)="editEntry(element)" *ngIf="element.verified == 0">
              Buchung bearbeiten
              <mat-icon>edit</mat-icon>
            </button>
          </ng-template>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>