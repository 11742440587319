import { ViewChild, TemplateRef, Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, FormGroup, FormControl } from '@angular/forms';
import * as $ from 'jquery';

import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

import { Login } from '../../util/login';
import { RouteGuard } from '../../util/guards/route.guard';
import { ConfigModule } from '../../util/config';
import { environment } from 'src/environments/environment';
import { SHA1 } from 'crypto-js';
import { Subscription, delay, filter, take } from 'rxjs';
import { AppCommunicationService, AutofillEvent, LoginEvent } from 'src/app/util/services/app-communication/app-communication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [RouteGuard],
})

export class LoginComponent implements OnInit, AfterViewInit {
  group = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    remember: new FormControl(false)
  });

  showSpinner: Boolean;
  redirectUrl: string;
  bgImageArray: string[] = ['bg2.jpg', 'bg3.jpg', 'bg4.jpg', 'bg1.jpg'];
  imageBase: string = '../../assets/bg/';
  fadeTime: number = 6;
  currentImageIndex: number = 1;
  autoFillAvailable = false;

  @ViewChild('wrongCredentials', { static: false }) wrongCredentials: TemplateRef<any>;
  @ViewChild('errorUpcoming', { static: false }) errorUpcoming: TemplateRef<any>;
  @ViewChild('noPermission', { static: false }) noPermission: TemplateRef<any>;

  env = environment;
  subscription: Subscription;

  constructor(private _formBuilder: UntypedFormBuilder,
    private _dialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute,
    private _config: ConfigModule,
    public _com: AppCommunicationService,
    private _login: Login) {
    this._config.setTitle('Login');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this._login.isLoggedIn().subscribe(loggedIn => {
      if (loggedIn) {
        this._router.navigate(['/home']);
      }
    });
    this._com.sendAction('requestAutofill');
    // Fix for android
    setTimeout(() => {
      this._com.sendAction('requestAutofill');
    }, 1000);
    this.autoFillAvailable = false;
    this.subscription = this._com.callback.subscribe(result => {
      if (result.result instanceof LoginEvent) {
        this.login(result.result.username, result.result.password);
        return;
      }
      if (result.result instanceof AutofillEvent) {
        this.autoFillAvailable = true;
        return;
      }
      this._com.callback.next({
        result: null
      });
    });
  }

  sendAutofillRequest() {
    this._com.sendAction('autofill')
  }

  login(username = '', password = '') {
    this.showSpinner = true;
    if (!username && !password && this.group.invalid) {
      return;
    }

    let encryptedPassword = password;
    if (username && password) {
      this.group.get('username').setValue(username);
      this.group.get('password').setValue(password);
      this.group.get('remember').setValue(true);
    } else encryptedPassword = SHA1(this.group.get('password').value).toString();

    this._login.login(this.group.get('username').value, encryptedPassword)
      .subscribe((response: any) => {
        if (response === true) {
          if (this.redirectUrl)
            this._router.navigate([this.redirectUrl]);
          else
            this._router.navigate(['/home']);
          this.env.userLoggedIn = true;
          if (this.group.get('remember').value) {
            this._com.sendAction('login', {
              username: this.group.get('username').value,
              password: encryptedPassword
            });
          }
        } else {
          this.showSpinner = false;
          const worngCredentialsDialog = this._dialog.open(this.wrongCredentials);
          worngCredentialsDialog.keydownEvents()
            .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
            .subscribe(() => worngCredentialsDialog.close());
        }
      }, error => {
        if (error.error === 5001)
          this._config.showSnackbar('Bitte erneut versuchen. Fehler bei der Verschlüsselung!', 5000, 'error');
        else
          console.log(error);
        this.showSpinner = false;
      });
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  ngOnInit() {
    this._route.queryParams
      .subscribe(params => {
        if (params.redirect && !params.redirect.endsWith('.html')) {
          this.redirectUrl = params.redirect;
        }
      });
  }
}
