import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomMaterialModule } from './util/material';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NavComponent, BottomSheetPWChangeNormal } from './components/nav/nav.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RouteGuard } from './util/guards/route.guard';

import { UntypedFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { CookieService } from 'ngx-cookie-service';
import { ResponderComponent, BottomSheetResponderShiftPopupComponent } from './components/responder/responder.component';
import { AdminGroupsComponent } from './components/admin/admin-groups/admin-groups.component';
import { Login } from './util/login';
import { NotFoundComponentComponent } from './util/error-pages/not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { ForbiddenComponent } from './util/error-pages/forbidden/forbidden.component';
import { ConfigModule, getHostname } from './util/config';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageCropperModule } from 'ngx-image-cropper';

import { WebConfigService } from './util/services/webconfig/webconfig.service';

import { AdminUserComponent } from './components/admin/admin-user/admin-user.component';

import { LOCALE_ID } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { registerLocaleData } from '@angular/common';
import { AdminUserMenusheetComponent } from './components/admin/admin-user/admin-user-detail/admin-user-menusheet/admin-user-menusheet.component';
import { UserComponent } from './components/user/user.component';
import { UserDetailComponent } from './components/user/user-detail/user-detail.component';
import { NetworkErrorComponent } from './util/error-pages/network-error/network-error.component';
import { AdminGroupsDetailComponent } from './components/admin/admin-groups/admin-groups-detail/admin-groups-detail.component';
import { AdminNotificationsComponent } from './components/admin/admin-notifications/admin-notifications.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PwaService } from './util/pwa/pwa.service';
import { UpdateService } from './util/pwa/update.service';
import { UpdateCheckService } from './util/pwa/update-check.service';
import { PromptUpdateService } from './util/pwa/update-prompt.service';
import { EventComponent, EventChangeStatusComponent } from './components/event/event.component';
import { PwforgotComponent, BottomSheetPWForgotComponent } from './components/nav/pwforgot/pwforgot.component';
import { AdminEventComponent } from './components/admin/admin-event/admin-event.component';
import { AdminEventDetailComponent } from './components/admin/admin-event/admin-event-detail/admin-event-detail.component';
import { EventDetailComponent, EventFinalComponent } from './components/event/event-detail/event-detail.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ThemingService } from './util/services/theming.service';
import { FileUploadService } from './util/services/fileUpload.service';
import { PreventCloseGuardService } from './util/guards/prevent-close.guard';
import { ProfileSettingsComponent } from './components/profile/profile-settings/profile-settings.component';
import { ProfileCalendarComponent } from './components/profile/profile-calendar/profile-calendar.component';
import { ProfileEmergencyContactsComponent } from './components/profile/profile-emergency-contacts/profile-emergency-contacts.component';
import { ProfileUserdataComponent } from './components/profile/profile-userdata/profile-userdata.component';
import { ProfileFiredataComponent } from './components/profile/profile-firedata/profile-firedata.component';
import { ProfileMaterialComponent } from './components/profile/profile-material/profile-material.component';
import { GuardsComponent } from './components/guards/guards.component';
import { EventsWatcherComponent } from './components/guards/events-watcher/events-watcher.component';
import { AdminUserFiredataComponent } from './components/admin/admin-user/admin-user-detail/admin-user-firedata/admin-user-firedata.component';
import { AdminUserGroupsComponent } from './components/admin/admin-user/admin-user-detail/admin-user-groups/admin-user-groups.component';
import { AdminUserEmergencycontactsComponent } from './components/admin/admin-user/admin-user-detail/admin-user-emergencycontacts/admin-user-emergencycontacts.component';
import { AdminUserAtemschutzComponent } from './components/admin/admin-user/admin-user-detail/admin-user-atemschutz/admin-user-atemschutz.component';
import { AdminUserCoursesComponent } from './components/admin/admin-user/admin-user-detail/admin-user-courses/admin-user-courses.component';
import { AdminUserDrivingComponent, AdminUserDrivingSheetComponent, AdminUserLicenceClassDialogComponent, AdminUserLicenceInfoDialogComponent } from './components/admin/admin-user/admin-user-detail/admin-user-driving/admin-user-driving.component';
import { SlidePanelComponent } from './components/nav/slide-panel/slide-panel.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NotificationsComponent } from './components/profile/notifications/notifications/notifications.component';
import { NotificationNavComponent } from './components/nav/notification-nav/notification-nav.component';
import { NotificationDetailComponent } from './components/profile/notifications/notification-detail/notification-detail.component';

import { QuillModule, provideQuillConfig } from 'ngx-quill'
import { ProfileDrivingComponent } from './components/profile/profile-driving/profile-driving.component';
import { LightboxModule } from 'ngx-lightbox';
import { MaschinistenComponent, BottomSheetNotificationMaschinistenComponent } from './components/admin/fields/maschinisten/maschinisten.component';
import { HelpComponent } from './components/help/help.component';
import { HelpHomeComponent } from './components/help/help-home/help-home.component';
import { HelpEventsComponent } from './components/help/help-event/help-events.component';
import { HelpResponderComponent } from './components/help/help-responder/help-responder.component';
import { HelpEvdComponent } from './components/help/help-evd/help-evd.component';
import { HelpAlarmsComponent } from './components/help/help-alarms/help-alarms.component';
import { HelpUserComponent } from './components/help/help-user/help-user.component';
import { HelpProfileComponent } from './components/help/help-profile/help-profile.component';
import { HelpSettingsComponent } from './components/help/help-settings/help-settings.component';
import { HelpGuardsComponent } from './components/help/help-guards/help-guards.component';
import { AdminUserDrivingAssetsComponent } from './components/admin/admin-user/admin-user-detail/admin-user-driving/admin-user-driving-assets/admin-user-driving-assets.component';
import { LoggingComponent, BottomSheetLogApiMessageComponent } from './components/admin/logging/logging.component';
import { AdminUserLicenceControlComponent } from './components/admin/admin-user/admin-user-detail/admin-user-driving/admin-user-licence-control/admin-user-licence-control.component';
import { ControlComponent, ClassesChangesControlComponent, LicenceChangesControlComponent } from './components/admin/control/control.component';
import { MonthDateHeaderComponent } from './util/customDateAdapter';
import { ProfileAtemschutzComponent } from './components/profile/profile-atemschutz/profile-atemschutz.component';
import { AdminUserAtemschutzAssetsComponent } from './components/admin/admin-user/admin-user-detail/admin-user-atemschutz/admin-user-atemschutz-assets/admin-user-atemschutz-assets.component';
import { AlarmsComponent } from './components/alarms/alarms-edit/alarms-edit.component';
import { AlarmsGeneralComponent } from './components/alarms/alarms-edit/alarms-general/alarms-general.component';
import { AlarmsOverviewComponent, BottomSheetAlarmReviewerNotesComponent } from './components/alarms/alarms-overview/alarms-overview.component';
import { AlarmsVehiclesComponent } from './components/alarms/alarms-edit/alarms-vehicles/alarms-vehicles.component';
import { AlarmsFezComponent } from './components/alarms/alarms-edit/alarms-fez/alarms-fez.component';
import { AlarmsReportComponent } from './components/alarms/alarms-edit/alarms-report/alarms-report.component';
import { AlarmsDetailsComponent } from './components/alarms/alarms-edit/alarms-details/alarms-details.component';
import { AlarmsInvoiceComponent } from './components/alarms/alarms-edit/alarms-invoice/alarms-invoice.component';
import { AlarmsDamageComponent } from './components/alarms/alarms-edit/alarms-damage/alarms-damage.component';
import { AddressDialogComponent } from './util/dialogs/address-dialog/address-dialog.component';
import {
  AlarmsVehiclesDialogComponent,
} from './components/alarms/alarms-edit/alarms-vehicles/alarms-vehicles-dialog/alarms-vehicles-dialog.component';
import { EvDComponent, BottomSheetEvDShiftPopupComponent } from './components/evd/evd.component';
import { AlarmsVehicleDialogPersonsComponent } from './components/alarms/alarms-edit/alarms-vehicles/alarms-vehicles-dialog/alarms-vehicle-dialog-persons/alarms-vehicle-dialog-persons.component';
import { AlarmsVehicleDialogMaterialComponent } from './components/alarms/alarms-edit/alarms-vehicles/alarms-vehicles-dialog/alarms-vehicle-dialog-material/alarms-vehicle-dialog-material.component';
import { AlarmsVehicleDialogInformationComponent } from './components/alarms/alarms-edit/alarms-vehicles/alarms-vehicles-dialog/alarms-vehicle-dialog-information/alarms-vehicle-dialog-information.component';
import { AlarmsFinalOverviewComponent } from './components/alarms/alarms-edit/alarms-final-overview/alarms-final-overview.component';
import { AlarmsReportTemplatesComponent } from './components/alarms/alarms-edit/alarms-report-templates/alarms-report-templates.component';

import { AlarmsConfirmationDialogComponent } from './util/dialogs/alarms-confirmation-dialog/alarms-confirmation-dialog.component';
import { AdminSettingsComponent } from './components/admin/admin-settings/admin-settings.component';
import { HttpAuthInterceptor } from './util/services/http-auth-interceptor.service';
import { ProfileAlarmsComponent } from './components/profile/profile-alarms/profile-alarms.component';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProfileMassExportAlarmsComponent } from './components/profile/profile-alarms/profile-mass-export-alarms/profile-mass-export-alarms.component';
import { AdminSettingsCronjobsComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-cronjobs/admin-settings-cronjobs.component';
import { AdminSettingsCronjobsModalComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-cronjobs/admin-settings-cronjobs-modal/admin-settings-cronjobs-modal.component';
import { AdminSettingsJobsComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-jobs/admin-settings-jobs.component';
import { AdminSettingsJobsModalComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-jobs/admin-settings-jobs-modal/admin-settings-jobs-modal.component';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { ViewSDKClient } from './util/proprietary/adobe/view-sdk.service';
import { AdminUserNewComponent } from './components/admin/admin-user/admin-user-new/admin-user-new.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { TableStatisticComponent } from './components/statistics/table-statistic/table-statistic.component';

import { NgxEchartsModule } from 'ngx-echarts';
import '../assets/echarts/chalk';
import '../assets/echarts/shine';
import { BarChartStatisticComponent } from './components/statistics/bar-chart-statistic/bar-chart-statistic.component';
import { PieChartStatisticComponent } from './components/statistics/pie-chart-statistic/pie-chart-statistic.component';
import { AtemschutzMenueComponent } from './components/admin/fields/atemschutz/atemschutz-menue/atemschutz-menue.component';
import { AtemschutzNotificationsComponent, BottomSheetNotificationAtemschutzComponent } from './components/admin/fields/atemschutz/atemschutz-notifications/atemschutz-notifications.component';
import { AtemschutzUsersComponent } from './components/admin/fields/atemschutz/atemschutz-users/atemschutz-users.component';
import { SecretaryMenueComponent } from './components/admin/fields/secretary/secretary-menue/secretary-menue.component';
import { AdminSettingsStatisticsComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-statistics/admin-settings-statistics.component';
import { AdminSettingsStatisticsModalComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-statistics/admin-settings-statistics-modal/admin-settings-statistics-modal.component';
import { MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCro } from './util/matPaginatorIntlCroClass';

import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

import { NgxMatTimelineModule } from "ngx-mat-timeline";

import { YearpickerComponent } from './util/pickers/datepickers/yearpicker/yearpicker.component';
import { MonthpickerComponent } from './util/pickers/datepickers/monthpicker/monthpicker.component';
import { BottomSheetEventInfoComponent } from './util/sheets/calendar-info/calendar-info-sheet.componet';
import { HelpStatisticsComponent } from './components/help/help-statistics/help-statistics.component';
import { StackbarChartStatisticComponent } from './components/statistics/stackbar-chart-statistic/stackbar-chart-statistic.component';
import { SecretaryCoursesComponent } from './components/admin/fields/secretary/secretary-courses/secretary-courses.component';
import { SecretaryCoursesDetailComponent } from './components/admin/fields/secretary/secretary-courses-detail/secretary-courses-detail.component';
import { SocialMediaComponent } from './components/admin/fields/social-media/social-media.component';
import { AdminGuardsComponent } from './components/admin/fields/guards/guards.component';
import { AdminGuardsPersonalComponent } from './components/admin/fields/guards/personal/personal.component';
import { AdminUserMasterdataComponent } from './components/admin/admin-user/admin-user-detail/admin-user-masterdata/admin-user-masterdata.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AdminUserNewDocumentsComponent } from './components/admin/admin-user/admin-user-detail/admin-user-new-documents/admin-user-new-documents.component';
import { AdminGuardsPersonalDetailsComponent } from './components/admin/fields/guards/personal/details/details.component';
import { AdminGuardsControlComponent } from './components/admin/fields/guards/guards-control/guards-control.component';
import { GuardLocationPipe } from './util/pipes/guard-location.pipe';
import { AdminGuardsEditComponent } from './components/admin/fields/guards/guards-control/edit/edit.component';
import { AdminGuardsAssignmentComponent } from './components/admin/fields/guards/assignment/assignment.component';
import { SignatureDialogComponent } from './util/dialogs/signature-dialog/signature-dialog.component';
import { UserNewCoursedataDialogComponent } from './util/dialogs/user-new-coursedata-dialog/user-new-coursedata-dialog.component';
import { AdminSettingsGeneralComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-general.component';
import { AdminSettingsAlarmlinesComponent } from './components/admin/admin-settings/admin-settings-fireplace/admin-settings-alarmlines/admin-settings-alarmlines.component';
import { AdminSettingsAlarmlinesModalComponent } from './components/admin/admin-settings/admin-settings-fireplace/admin-settings-alarmlines/admin-settings-alarmlines-modal/admin-settings-alarmlines-modal.component';
import { EventListComponent } from './components/event/event-list/event-list.component';
import { EventCalendarComponent } from './components/event/event-calendar/event-calendar.component';
import { ProfileTimelineComponent } from './components/profile/profile-timeline/profile-timeline.component';

import { IconPickerDialogComponent } from './util/dialogs/icon-picker-dialog/icon-picker-dialog.component';
import { AdmingGuardsAssignmentSendComponent } from './components/admin/fields/guards/assignment/send/send.component';
import { SecretaryCoursesCategoriesComponent } from './components/admin/fields/secretary/secretary-courses-categories/secretary-courses-categories.component';
import { MonthYearpickerComponent } from './util/pickers/datepickers/month-yearpicker/month-yearpicker.component';
import { GrouppickerComponent } from './util/pickers/grouppicker/grouppicker.component';
import { SecretaryCoursesCategoriesDialogComponent } from './components/admin/fields/secretary/secretary-courses-categories/secretary-courses-categories-dialog/secretary-courses-categories-dialog.component';
import { AdminSettingsJobHistoryComponent } from './components/admin/admin-settings/admin-settings-general/admin-settings-cronjobs/admin-settings-job-history/admin-settings-job-history.component';

import { NgxPrintModule } from 'ngx-print';

import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { EquipmentComponent } from './components/equipment/equipment.component';
import { MaterialComponent } from './components/equipment/material/material.component';
import { MaterialChecksComponent } from './components/equipment/material-checks/material-checks.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { QrScannerComponent } from './util/dialogs/qr-scanner/qr-scanner.component';
import { VehiclesComponent } from './components/equipment/vehicles/vehicles.component';
import { StorageComponent } from './components/equipment/storage/storage.component';
import { StorageDetailComponent } from './components/equipment/storage/storage-detail/storage-detail.component';
import { MaterialChecksDetailComponent } from './components/equipment/material-checks/material-checks-detail/material-checks-detail.component';
import { MaterialDetailComponent } from './components/equipment/material/material-detail/material-detail.component';
import { AdminGuardsVerifiedSendComponent } from './components/admin/fields/guards/guards-control/send/send.component';
import { AdminGuardsAccountingComponent } from './components/admin/fields/guards/accounting/accounting.component';
import { AdminGuardsAccountingSendComponent } from './components/admin/fields/guards/accounting/send/send.component';
import { MaterialLocationsComponent } from './components/equipment/material-locations/material-locations.component';
import { MaterialLocationsDetailComponent } from './components/equipment/material-locations/material-locations-detail/material-locations-detail.component';
import { VehiclesDetailComponent } from './components/equipment/vehicles/vehicles-detail/vehicles-detail.component';
import { CategoryComponent } from './components/equipment/category/category.component';
import { CategoryDetailComponent } from './components/equipment/category/category-detail/category-detail.component';
import { NotificationSettingsComponent } from './components/profile/profile-settings/notification-settings/notification-settings.component';
import { CountUpDirective } from './directives/count-up/count-up.directive';
import { CheckCyclesComponent } from './components/equipment/check-cycles/check-cycles.component';
import { CheckCyclesDetailComponent } from './components/equipment/check-cycles/check-cycles-detail/check-cycles-detail.component';
import { QRCodeModule } from 'angularx-qrcode';
import { RankpickerComponent } from './util/pickers/rankpicker/rankpicker.component';

import { AppCommunicationService } from './util/services/app-communication/app-communication.service';
import { NfcScannerDialogComponent } from './util/dialogs/nfc-scanner-dialog/nfc-scanner-dialog.component';
import { ChecksCycleDateChangeComponent } from './components/equipment/check-cycles/check-cycles-detail/checks-cycle-date-change/checks-cycle-date-change.component';
import { UserLeaveOrganizationComponent } from './util/dialogs/user-leave-organization/user-leave-organization.component';
import { TimeTrackingComponent } from './components/time-tracking/time-tracking.component';
import { CreateTimeTrackingDialogComponent } from './util/dialogs/create-time-tracking-dialog/create-time-tracking-dialog.component';
import { TimeTrackingAdminComponent } from './components/time-tracking/time-tracking-admin/time-tracking-admin.component';
import { TimeTrackingMonthListComponent } from './components/time-tracking/time-tracking-month-list/time-tracking-month-list.component';
import { TimeTrackingDayListComponent } from './components/time-tracking/time-tracking-day-list/time-tracking-day-list.component';
import { TimeTrackingAdminCategoriesComponent } from './components/time-tracking/time-tracking-admin/time-tracking-admin-categories/time-tracking-admin-categories.component';
import { CreateTimeCategoryDialogComponent } from './util/dialogs/create-time-category-dialog/create-time-category-dialog.component';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    LoginComponent,
    HomeComponent,
    ResponderComponent,
    AdminGroupsComponent,
    NotFoundComponentComponent,
    ForbiddenComponent,
    AdminUserComponent,
    AdminUserMenusheetComponent,
    UserComponent,
    UserDetailComponent,
    NetworkErrorComponent,
    AdminGroupsDetailComponent,
    AdminNotificationsComponent,
    BottomSheetEventInfoComponent,
    EventComponent,
    PwforgotComponent,
    BottomSheetPWForgotComponent,
    BottomSheetPWChangeNormal,
    AdminEventComponent,
    AdminEventDetailComponent,
    EventChangeStatusComponent,
    BottomSheetResponderShiftPopupComponent,
    EventDetailComponent,
    EventFinalComponent,
    ProfileComponent,
    ProfileSettingsComponent,
    ProfileCalendarComponent,
    ProfileEmergencyContactsComponent,
    ProfileUserdataComponent,
    ProfileFiredataComponent,
    ProfileMaterialComponent,
    GuardsComponent,
    EventsWatcherComponent,
    AdminUserMasterdataComponent,
    AdminUserFiredataComponent,
    AdminUserGroupsComponent,
    AdminUserEmergencycontactsComponent,
    AdminUserAtemschutzComponent,
    AdminUserCoursesComponent,
    AdminUserDrivingComponent,
    AdminUserDrivingSheetComponent,
    AdminUserLicenceClassDialogComponent,
    AdminUserLicenceInfoDialogComponent,
    SlidePanelComponent,
    NotificationsComponent,
    NotificationNavComponent,
    NotificationDetailComponent,
    ProfileDrivingComponent,
    MaschinistenComponent,
    BottomSheetNotificationMaschinistenComponent,
    HelpComponent,
    HelpHomeComponent,
    HelpEventsComponent,
    HelpResponderComponent,
    HelpEvdComponent,
    HelpAlarmsComponent,
    HelpUserComponent,
    HelpProfileComponent,
    HelpSettingsComponent,
    HelpGuardsComponent,
    AdminUserDrivingAssetsComponent,
    LoggingComponent,
    BottomSheetLogApiMessageComponent,
    AdminUserLicenceControlComponent,
    ControlComponent,
    LicenceChangesControlComponent,
    ClassesChangesControlComponent,
    MonthDateHeaderComponent,
    ProfileAtemschutzComponent,
    AdminUserAtemschutzAssetsComponent,
    AlarmsComponent,
    AlarmsGeneralComponent,
    AlarmsOverviewComponent,
    AlarmsVehiclesComponent,
    AlarmsFezComponent,
    AlarmsReportComponent,
    AlarmsDetailsComponent,
    AlarmsInvoiceComponent,
    AlarmsDamageComponent,
    AddressDialogComponent,
    AlarmsVehiclesDialogComponent,
    EvDComponent,
    BottomSheetEvDShiftPopupComponent,
    AlarmsVehicleDialogPersonsComponent,
    AlarmsVehicleDialogMaterialComponent,
    AlarmsVehicleDialogInformationComponent,
    AlarmsFinalOverviewComponent,
    AlarmsReportTemplatesComponent,
    AlarmsConfirmationDialogComponent,
    AdminSettingsComponent,
    BottomSheetAlarmReviewerNotesComponent,
    ProfileAlarmsComponent,
    ProfileMassExportAlarmsComponent,
    AdminSettingsCronjobsComponent,
    AdminSettingsCronjobsModalComponent,
    AdminSettingsJobsComponent,
    AdminSettingsJobsModalComponent,
    AdminUserNewComponent,
    StatisticsComponent,
    TableStatisticComponent,
    BarChartStatisticComponent,
    PieChartStatisticComponent,
    AtemschutzMenueComponent,
    AtemschutzUsersComponent,
    AtemschutzNotificationsComponent,
    SecretaryMenueComponent,
    BottomSheetNotificationAtemschutzComponent,
    AdminSettingsStatisticsComponent,
    AdminSettingsStatisticsModalComponent,
    YearpickerComponent,
    MonthpickerComponent,
    HelpStatisticsComponent,
    StackbarChartStatisticComponent,
    SecretaryCoursesComponent,
    SecretaryCoursesDetailComponent,
    SocialMediaComponent,
    AdminGuardsComponent,
    AdminGuardsPersonalComponent,
    AdminUserNewDocumentsComponent,
    AdminGuardsPersonalDetailsComponent,
    AdminGuardsControlComponent,
    GuardLocationPipe,
    AdminGuardsEditComponent,
    SignatureDialogComponent,
    UserNewCoursedataDialogComponent,
    AdminSettingsGeneralComponent,
    AdminSettingsAlarmlinesComponent,
    AdminSettingsAlarmlinesModalComponent,
    AdminGuardsAssignmentComponent,
    EventListComponent,
    EventCalendarComponent,
    ProfileTimelineComponent,
    AdmingGuardsAssignmentSendComponent,
    IconPickerDialogComponent,
    SecretaryCoursesCategoriesComponent,
    MonthYearpickerComponent,
    GrouppickerComponent,
    SecretaryCoursesCategoriesDialogComponent,
    AdminSettingsJobHistoryComponent,
    EquipmentComponent,
    MaterialComponent,
    MaterialChecksComponent,
    QrScannerComponent,
    VehiclesComponent,
    StorageComponent,
    StorageDetailComponent,
    MaterialChecksDetailComponent,
    MaterialDetailComponent,
    AdminGuardsVerifiedSendComponent,
    AdminGuardsAccountingComponent,
    AdminGuardsAccountingSendComponent,
    MaterialLocationsComponent,
    MaterialLocationsDetailComponent,
    VehiclesDetailComponent,
    CategoryComponent,
    CategoryDetailComponent,
    NotificationSettingsComponent,
    CountUpDirective,
    CheckCyclesComponent,
    CheckCyclesDetailComponent,
    RankpickerComponent,
    NfcScannerDialogComponent,
    ChecksCycleDateChangeComponent,
    UserLeaveOrganizationComponent,
    TimeTrackingComponent,
    CreateTimeTrackingDialogComponent,
    TimeTrackingAdminComponent,
    TimeTrackingMonthListComponent,
    TimeTrackingDayListComponent,
    TimeTrackingAdminCategoriesComponent,
    CreateTimeCategoryDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CustomMaterialModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    NgxSpinnerModule,
    Login,
    RouterModule,
    ConfigModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxMatTimelineModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    QuillModule,
    ImageCropperModule,
    LightboxModule,
    SweetAlert2Module.forRoot(),
    NgxSkeletonLoaderModule,
    NgxMatSelectSearchModule,
    ViewSDKClient,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    ScrollingModule,
    AngularSignaturePadModule,
    NgxJsonViewerModule,
    NgxScannerQrcodeModule,
    NgxPrintModule,
    NgxMatTimepickerModule.setLocale('de-DE'),
    QRCodeModule
  ],
  providers: [
    UntypedFormBuilder,
    CookieService,
    RouteGuard,
    { provide: LOCALE_ID, useValue: 'de' },
    BottomSheetEventInfoComponent,
    PwaService,
    UpdateService,
    UpdateCheckService,
    PromptUpdateService,
    ThemingService,
    FileUploadService,
    WebConfigService,
    AppCommunicationService,
    MatDatepickerModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true,
    },
    PreventCloseGuardService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    provideQuillConfig({
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike', 'link'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'indent': '-1'}, { 'indent': '+1' }]
      ]
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
