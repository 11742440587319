import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';
import { Login } from '../../util/login';
import { getHostUrl, ConfigModule } from '../../util/config';
import { MatPaginatorIntlCro } from '../../util/matPaginatorIntlCroClass';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import * as $ from 'jquery';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { AdminUserNewComponent } from '../admin/admin-user/admin-user-new/admin-user-new.component';

export interface User {
  id: number;
  name: string,
  firstname: string;
  lastname: string;
  groups: any;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
})
export class UserComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['id', 'name', 'email'];

  dataSource = new MatTableDataSource();

  sortType: boolean = false;

  userList: User[] = [];

  groupList: any[] = [];

  groupsFilter: any[] = [];

  searchFilter: string = '';
  pageIndex: number = 0;
  pageSize: number = 20;

  showLoader = true;

  env = environment;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    public _login: Login,
    private _config: ConfigModule,
    private _ref: ChangeDetectorRef,
    public _dialog: MatDialog
  ) { }

  ngOnInit() {
    this._config.setTitle('Kontakte');
    this.refreshData();
    this.fetchDataInfo();

    if (!this._login.hasPermission('user-list'))
      this._router.navigate(['/403']);

    this.checkWidth();
    $(window).on('resize', () => {
      this.checkWidth();
    });
  }

  checkWidth() {
    if ($(window).width() < 800) {
      this.displayedColumns = ['id', 'name'];
    } else {
      this.displayedColumns = ['id', 'name', 'email'];
    }
  }

  applyHashLocation() {
    this.groupsFilter = this.getLocalStorage().groups;
    this.searchFilter = this.getLocalStorage().search;
    this.pageIndex = this.getLocalStorage().page;
    this.pageSize = this.getLocalStorage().size;
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, groups: this.groupsFilter });
    this._ref.detectChanges();
  }

  getLocalStorage() {
    const item = JSON.parse(localStorage.getItem('userFilter-'  + this._login.getUserID()) ?? '{}');

    return {
      search: item?.search ?? '',
      groups: item?.groups ?? [],
      size: item?.size ?? 10,
      page: item?.page ?? 1
    }
  }

  setHashLocation() {
    localStorage.setItem('userFilter-' + this._login.getUserID(), JSON.stringify({
      search: this.searchFilter, page: this.pageIndex, groups: this.groupsFilter, size: this.pageSize
    }));
  }

  refreshData() {

    this._http
      .get<User[]>(
        getHostUrl() + 'user/all',
      )
      .subscribe(data => {
        this.userList = data;
        this.dataSource = new MatTableDataSource<User>();
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'name': return item.lastname;
            default: return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.filter();
        this.dataSource.filter = JSON.stringify({ search: this.searchFilter, groups: this.groupsFilter });
        this.showLoader = false;
      });
  }

  fetchDataInfo() {
    this._http.get<any[]>(`${getHostUrl()}datainfo/groups?type=user`).subscribe(result => {
      this.groupList = result;
      this.applyHashLocation();
      this._ref.detectChanges();
    });
  }

  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, groups: this.groupsFilter });
    this.setHashLocation();
  }

  applyGroupFilter($event: MatSelectChange) {
    this.groupsFilter = $event.value;
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, groups: this.groupsFilter });
    this.setHashLocation();
  }

  filter(): (data: User, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      if (searchTerms.groups.length !== 0) {
        var result = true;
        searchTerms.groups.forEach(group => {
          const qGroup = parseInt(group);
          if (!data.groups.includes(qGroup))
            result = false;
          if (data.id.toString().indexOf(searchTerms.search.toLowerCase()) === -1 && data.name.toLowerCase().indexOf(searchTerms.search.toLowerCase()) === -1 && data.email.toLowerCase().indexOf(searchTerms.search.toLowerCase()) === -1)
            result = false;
        });
        return result;
      } else {
        if (data.groups.includes(21))
          return false;
        return (data.id.toString().indexOf(searchTerms.search.toLowerCase()) !== -1 || data.name.toLowerCase().indexOf(searchTerms.search.toLowerCase()) !== -1 || data.email.toLowerCase().indexOf(searchTerms.search.toLowerCase()) !== -1);
      }
    };
    return filterFunction;
  }

  applyPagination($event: PageEvent) {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.setHashLocation();
  }

  openNewUser() {
    this._dialog.open(AdminUserNewComponent, {
      width: '1300px',
      maxHeight: '90vh'
    });
  }
}
