<div class="alert alert-info">
  <b>Hinweis!</b> Mit der Eingabe deiner Führerschein Daten stimmst du der Verarbeitung/Speicherung zu.
</div>

<div class="row">
  <div class="col-12">
    <mat-form-field id="lastChecked" class="input-full-width" appearance="outline" matTooltip="{{getCheckedExpiry()}}"
      matTooltipPosition="above">
      <mat-icon matPrefix *ngIf="controlDateValid() === 1;" color="primary">warning</mat-icon>
      <mat-icon matPrefix *ngIf="controlDateValid() === 2;" color="warn">error</mat-icon>
      <mat-label>Letzte Kontrolle</mat-label>
      <input matInput placeholder="Letzte Kontrolle" [value]="getFormattedDate(parent.userInfo.driving.lastChecked)"
        disabled required>
      <button mat-icon-button matSuffix (click)="renewValidation()" matTooltip="Validierung erneuern">
        <mat-icon>nfc</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-12">
    <mat-form-field id="dateOfIssue" *ngIf="!parent.env.mobile" class="input-full-width" appearance="outline">
      <mat-label>Ausstellungsdatum (4a)</mat-label>
      <input matInput [matDatepicker]="dateOfIssue" placeholder="Ausstellungsdatum Führerschein"
        [min]="parent.age18Date" [value]="parent.userInfo.driving.dateOfIssue"
        (dateChange)="ondateOfIssueChange($event)" [max]="today" (change)="onissueDateChange($event)"
        (focus)="dateOfIssue.open()" required [disabled]="!firstLicence || (firstLicence && !licenceUploadValid)">
      <mat-datepicker-toggle matSuffix [for]="dateOfIssue" *ngIf="firstLicence"></mat-datepicker-toggle>
      <mat-datepicker #dateOfIssue [startAt]="startDate" startView="multi-year"></mat-datepicker>
    </mat-form-field>
    <mat-form-field id="dateOfIssue" *ngIf="parent.env.mobile" class="input-full-width" appearance="outline">
      <mat-label>Ausstellungsdatum (4a)</mat-label>
      <input matInput [matDatepicker]="dateOfIssue" placeholder="Ausstellungsdatum Führerschein"
        [min]="parent.age18Date" [value]="parent.userInfo.driving.dateOfIssue"
        (dateChange)="ondateOfIssueChange($event)" [max]="today" (change)="onissueDateChange($event)"
        (focus)="dateOfIssue.open()" required [disabled]="!firstLicence || (firstLicence && !licenceUploadValid)">
      <mat-datepicker-toggle matSuffix [for]="dateOfIssue" *ngIf="firstLicence"></mat-datepicker-toggle>
      <mat-datepicker #dateOfIssue touchUi [startAt]="startDate" startView="multi-year"></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-12">
    <mat-form-field id="dateOfExpiry" *ngIf="!parent.env.mobile && !unlimited" class="input-full-width"
      appearance="outline">
      <mat-label>Ablaufdatum (4b)</mat-label>
      <input matInput [matDatepicker]="dateOfExpiry" [min]="parent.userInfo.driving.dateOfIssue" [max]="expiryLicence"
        placeholder="Ablaufdatum Führerschein" [value]="parent.userInfo.driving.dateOfExpiry"
        (dateChange)="ondateOfExpiryChange($event)" (change)="onexpiryDateChange($event)" (focus)="dateOfExpiry.open()"
        required
        [disabled]="parent.userInfo.driving.dateOfIssue===null || !firstLicence || (firstLicence && !licenceUploadValid)">
      <mat-datepicker-toggle matSuffix [for]="dateOfExpiry" *ngIf="firstLicence"></mat-datepicker-toggle>
      <mat-datepicker #dateOfExpiry [startAt]="startDate" startView="multi-year"></mat-datepicker>
    </mat-form-field>
    <mat-form-field id="dateOfExpiry" *ngIf="parent.env.mobile && !unlimited" class="input-full-width"
      appearance="outline">
      <mat-label>Ablaufdatum (4b)</mat-label>
      <input matInput [matDatepicker]="dateOfExpiry" [min]="parent.userInfo.driving.dateOfIssue" [max]="expiryLicence"
        placeholder="Ablaufdatum Führerschein" [value]="parent.userInfo.driving.dateOfExpiry"
        (dateChange)="ondateOfExpiryChange($event)" (change)="onexpiryDateChange($event)" (focus)="dateOfExpiry.open()"
        required
        [disabled]="parent.userInfo.driving.dateOfIssue===null || !firstLicence || (firstLicence && !licenceUploadValid)">
      <mat-datepicker-toggle matSuffix [for]="dateOfExpiry" *ngIf="firstLicence"></mat-datepicker-toggle>
      <mat-datepicker #dateOfExpiry touchUi [startAt]="startDate" startView="multi-year"></mat-datepicker>
    </mat-form-field>
    <mat-form-field id="dateOfExpiry" class="input-full-width" appearance="outline" *ngIf="unlimited">
      <mat-label>Ablaufdatum Führerschein</mat-label>
      <input matInput placeholder="Ablaufdatum Führerschein" value="unbegrenzt" disabled required>
    </mat-form-field>
  </div>
  <div class="col-lg-5 col-md-6 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Ausstellungsbehörde (4c)</mat-label>
      <input matInput [(ngModel)]="parent.userInfo.driving.issuingAuthority" [matAutocomplete]="auto" required
        [disabled]="!firstLicence || (firstLicence && !licenceUploadValid)">
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option value="Landratsamt München">Landratsamt München</mat-option>
      <mat-option value="Landeshauptstadt München">Landeshauptstadt München</mat-option>
    </mat-autocomplete>
  </div>
  <div class="col-lg-5 col-md-6 col-12">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Führerscheinnummer (5)</mat-label>
      <input matInput [(ngModel)]="parent.userInfo.driving.dlNumber" required
        [disabled]="!firstLicence || (firstLicence && !licenceUploadValid)">
    </mat-form-field>
  </div>
  <div class="col-lg-2 col-md-12 col-12 center" [ngClass]="parent.env.mobile ? 'mobile' : ''">
    <div class="editButtons">
      <button mat-raised-button color="primary" (click)="openLicenceInfo()" *ngIf="!firstLicence">
        <mat-icon class="icon">edit</mat-icon> bearbeiten
      </button>
      <button mat-raised-button color="success" (click)="saveDriving()" *ngIf="firstLicence"
        [disabled]="!licenceUploadValid || parent.userInfo.driving.dateOfIssue===null || parent.userInfo.driving.issuingAuthority === '' || parent.userInfo.driving.dlNumber === ''">Speichern
        <mat-icon>save
        </mat-icon>
      </button>
    </div>
  </div>
</div>
<div class="row licences" *ngFor="let licence of licencesFiltered('1');">
  <div class="col-lg-2 col-md-6 col-6">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Führerscheinklasse</mat-label>
      <input matInput [(ngModel)]="licence.licenceClass" disabled>
    </mat-form-field>
  </div>
  <div class="col-lg-2 col-md-6 col-6">
    <mat-form-field class="input-full-width" appearance="outline" *ngIf="!unlimitedClass">
      <mat-label>Ablaufdatum</mat-label>
      <input matInput [value]="getFormattedDate(licence.expiryDate)" disabled>
      <button mat-icon-button matSuffix (click)="extendingLicenceDate(licence.licenceClass)"
        *ngIf="licence.licenceClass.startsWith('Klasse C')" matTooltip="{{licence.licenceClass}} verlängern">
        <mat-icon>update</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="input-full-width" appearance="outline" *ngIf="unlimitedClass">
      <mat-label>Ablaufdatum</mat-label>
      <input matInput value="unbegrenzt" disabled>
    </mat-form-field>
  </div>
  <div class="col-12" [ngClass]="licence.licenceClass !== 'Klasse FFF' ? 'col-lg-4 col-md-6' : 'col-lg-8 col-md-12'">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Fahrerlaubnis</mat-label>
      <mat-select multiple [(ngModel)]="licence.permission" class="fahrerlaubnis" disabled>
        <mat-option *ngFor="let car of getCarList(licence.licenceClass)" [value]="car.id">
          {{car.type}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-md-6 col-12" *ngIf="licence.licenceClass !== 'Klasse FFF'">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Blaufahrerlaubnis</mat-label>
      <mat-select multiple [(ngModel)]="licence.permissionBlue" class="fahrerlaubnis" disabled>
        <mat-option *ngFor="let car of getCarList(licence.licenceClass)" [value]="car.id">
          {{car.type}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div *ngIf="licencesFiltered('0').length!==0">
  <hr />
  <span>Folgende Klassen wurden noch nicht geprüft:</span>
  <div class="row licences" style="margin-top: 15px;" *ngFor="let licence of licencesFiltered('0');">
    <div class="col-lg-2 col-md-6 col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Führerscheinklasse</mat-label>
        <input matInput [(ngModel)]="licence.licenceClass" disabled>
      </mat-form-field>
    </div>
    <div class="col-lg-2 col-md-6 col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Ablaufdatum</mat-label>
        <input matInput [value]="getFormattedDate(licence.expiryDate)" disabled>
        <button mat-icon-button matSuffix (click)="extendingLicenceDate(licence.licenceClass)"
          *ngIf="licence.licenceClass.startsWith('Klasse C')" matTooltip="{{licence.licenceClass}} verlängern">
          <mat-icon>update</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-12" [ngClass]="licence.licenceClass !== 'Klasse FFF' ? 'col-lg-4 col-md-6' : 'col-lg-8 col-md-12'">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Fahrerlaubnis</mat-label>
        <mat-select multiple [(ngModel)]="licence.permission" class="fahrerlaubnis" disabled>
          <mat-option *ngFor="let car of getCarList(licence.licenceClass)" [value]="car.id">
            {{car.type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-6 col-12" *ngIf="licence.licenceClass !== 'Klasse FFF'">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Blaufahrerlaubnis</mat-label>
        <mat-select multiple [(ngModel)]="licence.permissionBlue" class="fahrerlaubnis" disabled>
          <mat-option *ngFor="let car of getCarList(licence.licenceClass)" [value]="car.id">
            {{car.type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="center" [ngClass]="parent.env.mobile ? 'mobilecenter' : ''">
  <div class="clearfix" [ngClass]="parent.env.mobile ? 'mobile, editButtons' : ''">
    <button mat-raised-button color="accent" class="float-left" (click)="openLicencePictureDialog()"
      style="margin-right: 15px; margin-top: 10px;">Führerschein Bilder
      <mat-icon>add_a_photo
      </mat-icon>
    </button>
    <button mat-raised-button color="accent" class="float-right" (click)="openFFFPictureDialog()"
      style="margin-right: 15px; margin-top: 15px;">Staplerschein Bilder
      <mat-icon>add_a_photo
      </mat-icon>
    </button>
  </div>
  <mat-error *ngIf="!licenceUploadValid && firstLicence" [ngClass]="parent.env.mobile ? 'mobile mobileText' : ''"
    class="float-left">
    Bitte erst ein Führerscheinbild hochladen.</mat-error>
</div>
<mat-tab-group dynamicHeight style="margin-top: 15px;" class="col-lg-12 col-12"
  *ngIf="parent.userInfo.driving.dienstfahrerlaubnis !== '' || parent.userInfo.driving.gabelstapler  !== '' || parent.userInfo.driving.fahrzeugkraene !== '' "
  mat-align-tabs="center" (selectedTabChange)="loadPdf($event)">
  <mat-tab label="Dienstfahrerlaubnis" *ngIf="parent.userInfo.driving.dienstfahrerlaubnis !== '' ">
  </mat-tab>
  <mat-tab label="Beauftragung Flurförderfahrzeuge" *ngIf="parent.userInfo.driving.flurfoerderfahrzeuge !== '' ">
  </mat-tab>
  <mat-tab label="Beauftragung Fahrzeugkräne" *ngIf="parent.userInfo.driving.fahrzeugkraene !== '' ">
  </mat-tab>
</mat-tab-group>

<div class="col-12" id="pdf"
  *ngIf="parent.userInfo.driving.dienstfahrerlaubnis !== '' || parent.userInfo.driving.flurfoerderfahrzeuge  !== '' || parent.userInfo.driving.fahrzeugkraene !== ''"
  [ngClass]="parent.env.mobile ? 'mobilepdf' : ''"></div>

<ng-template #licenseDialog>
  <mat-dialog-header>
    <div class="clearfix">
      <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
        <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Führerschein Upload</h2>
    </div>
  </mat-dialog-header>
  <mat-dialog-content>
    <div class="alert alert-info">
      <b>Hinweis!</b> Mit dem Upload deiner Führerschein Daten stimmst du der Verarbeitung/Speicherung zu.
    </div>
    <div class="row">
      <div class="col-lg-6 col-12 container" *ngIf="!frontLoading" layout="column" layout-align="space-between center"
        style="display:flex;justify-content:center;align-items:center;">
        <img src="{{parent.userInfo.driving.front}}" class="image" />
        <div class="upload">
          <label for="uploadFront">
            <span class="uploadLabel">Vorderseite hochladen</span>
          </label>
          <input type="file" [(ngModel)]="frontFileUrl" (change)="uploadFront($event)" style="display:none;"
            id="uploadFront" accept="image/x-png,image/jpeg,image/jpg" />
        </div>
      </div>
      <div class="col-lg-6 col-12 container" *ngIf="frontLoading" layout="column" layout-align="space-between center"
        style="display:flex;justify-content:center;align-items:center;">
        <mat-spinner color="primary"></mat-spinner>
      </div>
      <div class="col-lg-6 col-12 container" *ngIf="!backLoading" layout="column" layout-align="space-between center"
        style="display:flex;justify-content:center;align-items:center;">
        <img src="{{parent.userInfo.driving.back}}" class="image" />
        <div class="upload">
          <label for="uploadBack">
            <span class="uploadLabel">Rückseite hochladen</span>
          </label>
          <input type="file" [(ngModel)]="backFileUrl" (change)="uploadBack($event)" style="display:none;"
            id="uploadBack" accept="image/x-png,image/jpeg,image/jpg" />
        </div>
      </div>
      <div class="col-lg-6 col-12 container" *ngIf="backLoading" layout="column" layout-align="space-between center"
        style="display:flex;justify-content:center;align-items:center;">
        <mat-spinner color="primary"></mat-spinner>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #resizeFront>
  <mat-dialog-header>
    <div class="clearfix">
      <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
        <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Bild zuschneiden</h2>
    </div>
  </mat-dialog-header>
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <mat-progress-bar mode="query" *ngIf="imageFrontLoading"></mat-progress-bar>
        <image-cropper [imageChangedEvent]="imageFrontChangedEvent" [maintainAspectRatio]="false" format="png"
          [canvasRotation]="turn" (imageLoaded)="imageFrontLoaded()" (imageCropped)="imageFrontCropped($event)"
          (loadImageFailed)="loadImageFailed()" [ngClass]="parent.env.mobile ? 'image-cropperMobile' : 'image-cropper'">
        </image-cropper>
      </div>
    </div>
    <div class="confirmation">
      <b>Bitte drehe dein Bild, dass es horizontal gelesen werden kann.</b><br><br>
      <mat-checkbox [(ngModel)]="confirmation" [disabled]="imageFrontLoading" color="primary">Hiermit bestätige ich,
        dass
        <span *ngIf="parent.env.mobile"><br></span>dies ein aktuelles Foto meines<span
          *ngIf="parent.env.mobile"><br></span> gültigen Führerscheins ist und <br>stimme der Verarbeitung und <span
          *ngIf="parent.env.mobile"><br></span>
        Speicherung meiner Daten zu.
      </mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: space-between">
    <button mat-mini-fab mat-dialog-close color="warn" (click)="closeReset()"><mat-icon>close</mat-icon></button>
    <div>
      <button mat-mini-fab color="accent" style="margin-left: 10px;" [disabled]="imageFrontLoading"
        (click)="turnLeft()"><mat-icon>undo</mat-icon></button>
      <button mat-mini-fab color="accent" style="margin-right: 10px;" [disabled]="imageFrontLoading"
        (click)="turnRight()"><mat-icon>redo</mat-icon></button>
    </div>
    <button mat-mini-fab mat-dialog-close [disabled]="!confirmation" (click)="saveFront()"
      color="success"><mat-icon>done</mat-icon></button>
  </mat-dialog-actions>
</ng-template>

<ng-template #resizeBack>
  <mat-dialog-header>
    <div class="clearfix">
      <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
        <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Bild zuschneiden</h2>
    </div>
  </mat-dialog-header>
  <mat-dialog-content>
    <mat-progress-bar mode="query" *ngIf="imageBackLoading"></mat-progress-bar>
    <image-cropper [imageChangedEvent]="imageBackChangedEvent" [maintainAspectRatio]="false" format="png"
      [canvasRotation]="turn" (imageLoaded)="imageBackLoaded()" (imageCropped)="imageBackCropped($event)"
      (loadImageFailed)="loadImageFailed()" [ngClass]="parent.env.mobile ? 'image-cropperMobile' : 'image-cropper'">
    </image-cropper>
    <div class="confirmation">
      <b>Bitte drehe dein Bild, dass es horizontal gelesen werden kann.</b><br><br>
      <mat-checkbox [(ngModel)]="confirmation" [disabled]="imageBackLoading" color="primary">Hiermit bestätige ich, dass
        <span *ngIf="parent.env.mobile"><br></span>dies ein aktuelles Foto meines<span
          *ngIf="parent.env.mobile"><br></span> gültigen Führerscheins ist und <br>stimme der Verarbeitung und <span
          *ngIf="parent.env.mobile"><br></span>
        Speicherung meiner Daten zu.
      </mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: space-between">
    <button mat-mini-fab mat-dialog-close color="warn" (click)="closeReset()"><mat-icon>close</mat-icon></button>
    <div>
      <button mat-mini-fab color="accent" style="margin-left: 10px;" [disabled]="imageBackLoading"
        (click)="turnLeft()"><mat-icon>undo</mat-icon></button>
      <button mat-mini-fab color="accent" style="margin-right: 10px;" [disabled]="imageBackLoading"
        (click)="turnRight()"><mat-icon>redo</mat-icon></button>
    </div>
    <button mat-mini-fab mat-dialog-close [disabled]="!confirmation" (click)="saveBack()"
      color="success"><mat-icon>done</mat-icon></button>
  </mat-dialog-actions>
</ng-template>

<ng-template #FFFDialog>
  <mat-dialog-header>
    <div class="clearfix">
      <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
        <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Staplerschein Upload</h2>
    </div>
  </mat-dialog-header>
  <mat-dialog-content>
    <div class="alert alert-info">
      <b>Hinweis!</b> Mit dem Upload deiner Führerschein Daten stimmst du der Verarbeitung/Speicherung zu.
    </div>
    <div class="row">
      <div class="col-lg-6 col-12 container" *ngIf="!FFFfrontLoading" layout="column"
        layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
        <img src="{{parent.userInfo.driving.FFFfront}}" class="image" />
        <div class="upload">
          <label for="uploadFront">
            <span class="uploadLabel">Vorderseite hochladen</span>
          </label>
          <input type="file" [(ngModel)]="FFFfrontFileUrl" (change)="FFFuploadFront($event)" style="display:none;"
            id="uploadFront" accept="image/x-png,image/jpeg,image/jpg" />
        </div>
      </div>
      <div class="col-lg-6 col-12 container" *ngIf="FFFfrontLoading" layout="column" layout-align="space-between center"
        style="display:flex;justify-content:center;align-items:center;">
        <mat-spinner color="primary"></mat-spinner>
      </div>
      <div class="col-lg-6 col-12 container" *ngIf="!FFFbackLoading" layout="column" layout-align="space-between center"
        style="display:flex;justify-content:center;align-items:center;">
        <img src="{{parent.userInfo.driving.FFFback}}" class="image" />
        <div class="upload">
          <label for="uploadBack">
            <span class="uploadLabel">Rückseite hochladen</span>
          </label>
          <input type="file" [(ngModel)]="FFFbackFileUrl" (change)="FFFuploadBack($event)" style="display:none;"
            id="uploadBack" accept="image/x-png,image/jpeg,image/jpg" />
        </div>
      </div>
      <div class="col-lg-6 col-12 container" *ngIf="FFFbackLoading" layout="column" layout-align="space-between center"
        style="display:flex;justify-content:center;align-items:center;">
        <mat-spinner color="primary"></mat-spinner>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #FFFresizeFront>
  <mat-dialog-header>
    <div class="clearfix">
      <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
        <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Bild zuschneiden</h2>
    </div>
  </mat-dialog-header>
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <mat-progress-bar mode="query" *ngIf="FFFimageFrontLoading"></mat-progress-bar>
        <image-cropper [imageChangedEvent]="FFFimageFrontChangedEvent" [maintainAspectRatio]="false" format="png"
          [canvasRotation]="turn" (imageLoaded)="FFFimageFrontLoaded()" (imageCropped)="FFFimageFrontCropped($event)"
          (loadImageFailed)="loadImageFailed()" [ngClass]="parent.env.mobile ? 'image-cropperMobile' : 'image-cropper'">
        </image-cropper>
      </div>
    </div>
    <div class="confirmation">
      <b>Bitte drehe dein Bild, dass es horizontal gelesen werden kann.</b><br><br>
      <mat-checkbox [(ngModel)]="confirmation" [disabled]="FFFimageFrontLoading" color="primary">Hiermit bestätige ich,
        dass
        <span *ngIf="parent.env.mobile"><br></span>dies ein aktuelles Foto meines<span
          *ngIf="parent.env.mobile"><br></span> gültigen Führerscheins ist und <br>stimme der Verarbeitung und <span
          *ngIf="parent.env.mobile"><br></span>
        Speicherung meiner Daten zu.
      </mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: space-between">
    <button mat-mini-fab mat-dialog-close color="warn" (click)="closeReset()"><mat-icon>close</mat-icon></button>
    <div>
      <button mat-mini-fab color="accent" style="margin-left: 10px;" [disabled]="FFFimageFrontLoading"
        (click)="turnLeft()"><mat-icon>undo</mat-icon></button>
      <button mat-mini-fab color="accent" style="margin-right: 10px;" [disabled]="FFFimageFrontLoading"
        (click)="turnRight()"><mat-icon>redo</mat-icon></button>
    </div>
    <button mat-mini-fab mat-dialog-close [disabled]="!confirmation" (click)="FFFsaveFront()"
      color="success"><mat-icon>done</mat-icon></button>
  </mat-dialog-actions>
</ng-template>

<ng-template #FFFresizeBack>
  <mat-dialog-header>
    <div class="clearfix">
      <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
        <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Bild zuschneiden</h2>
    </div>
  </mat-dialog-header>
  <mat-dialog-content>
    <mat-progress-bar mode="query" *ngIf="FFFimageBackLoading"></mat-progress-bar>
    <image-cropper [imageChangedEvent]="FFFimageBackChangedEvent" [maintainAspectRatio]="false" format="png"
      [canvasRotation]="turn" (imageLoaded)="FFFimageBackLoaded()" (imageCropped)="FFFimageBackCropped($event)"
      (loadImageFailed)="loadImageFailed()" [ngClass]="parent.env.mobile ? 'image-cropperMobile' : 'image-cropper'">
    </image-cropper>
    <div class="confirmation">
      <b>Bitte drehe dein Bild, dass es horizontal gelesen werden kann.</b><br><br>
      <mat-checkbox [(ngModel)]="confirmation" [disabled]="FFFimageBackLoading" color="primary">Hiermit bestätige ich,
        dass
        <span *ngIf="parent.env.mobile"><br></span>dies ein aktuelles Foto meines<span
          *ngIf="parent.env.mobile"><br></span> gültigen Führerscheins ist und <br>stimme der Verarbeitung und <span
          *ngIf="parent.env.mobile"><br></span>
        Speicherung meiner Daten zu.
      </mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: space-between">
    <button mat-mini-fab mat-dialog-close color="warn" (click)="closeReset()"><mat-icon>close</mat-icon></button>
    <div>
      <button mat-mini-fab color="accent" style="margin-left: 10px;" [disabled]="FFFimageBackLoading"
        (click)="turnLeft()"><mat-icon>undo</mat-icon></button>
      <button mat-mini-fab color="accent" style="margin-right: 10px;" [disabled]="FFFimageBackLoading"
        (click)="turnRight()"><mat-icon>redo</mat-icon></button>
    </div>
    <button mat-mini-fab mat-dialog-close [disabled]="!confirmation" (click)="FFFsaveBack()"
      color="success"><mat-icon>done</mat-icon></button>
  </mat-dialog-actions>
</ng-template>

<ng-template #extendLicenceDate>
  <mat-dialog-header>
    <div class="clearfix">
      <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
        <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Führerschein {{extendClass}} verlängern</h2>
    </div>
  </mat-dialog-header>
  <mat-dialog-content>
    <div class="row" *ngIf="licenceUploadValid">
      <div class="col-lg-12 col-12">
        <mat-form-field id="dateOfIssue" *ngIf="!parent.env.mobile" class="input-full-width" appearance="outline">
          <mat-label>Ausstellungsdatum (4a)</mat-label>
          <input matInput [matDatepicker]="dateOfIssue" placeholder="Ausstellungsdatum Führerschein"
            [min]="parent.age18Date" [value]="parent.userInfo.driving.dateOfIssue"
            (dateChange)="ondateOfIssueChange($event)" [max]="today" (change)="onissueDateChange($event)"
            (focus)="dateOfIssue.open()" required>
          <mat-datepicker-toggle matSuffix [for]="dateOfIssue"></mat-datepicker-toggle>
          <mat-datepicker #dateOfIssue [startAt]="startDate" startView="multi-year"></mat-datepicker>
        </mat-form-field>
        <mat-form-field id="dateOfIssue" *ngIf="parent.env.mobile" class="input-full-width" appearance="outline">
          <mat-label>Ausstellungsdatum (4a)</mat-label>
          <input matInput [matDatepicker]="dateOfIssue" placeholder="Ausstellungsdatum Führerschein"
            [min]="parent.age18Date" [value]="parent.userInfo.driving.dateOfIssue"
            (dateChange)="ondateOfIssueChange($event)" [max]="today" (change)="onissueDateChange($event)"
            (focus)="dateOfIssue.open()" required>
          <mat-datepicker-toggle matSuffix [for]="dateOfIssue"></mat-datepicker-toggle>
          <mat-datepicker #dateOfIssue touchUi [startAt]="startDate" startView="multi-year"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-12">
        <mat-form-field id="dateOfExpiry" *ngIf="!parent.env.mobile" class="input-full-width" appearance="outline">
          <mat-label>Ablaufdatum (4b)</mat-label>
          <input matInput [matDatepicker]="dateOfExpiry" [min]="parent.userInfo.driving.dateOfIssue"
            [max]="expiryLicence" placeholder="Ablaufdatum Führerschein" [value]="parent.userInfo.driving.dateOfExpiry"
            (dateChange)="ondateOfExpiryChange($event)" (change)="onexpiryDateChange($event)"
            (focus)="dateOfExpiry.open()" required [disabled]="parent.userInfo.driving.dateOfIssue===null">
          <mat-datepicker-toggle matSuffix [for]="dateOfExpiry"></mat-datepicker-toggle>
          <mat-datepicker #dateOfExpiry [startAt]="startDate" startView="multi-year"></mat-datepicker>
        </mat-form-field>
        <mat-form-field id="dateOfExpiry" *ngIf="parent.env.mobile" class="input-full-width" appearance="outline">
          <mat-label>Ablaufdatum (4b)</mat-label>
          <input matInput [matDatepicker]="dateOfExpiry" [min]="parent.userInfo.driving.dateOfIssue"
            [max]="expiryLicence" placeholder="Ablaufdatum Führerschein" [value]="parent.userInfo.driving.dateOfExpiry"
            (dateChange)="ondateOfExpiryChange($event)" (change)="onexpiryDateChange($event)"
            (focus)="dateOfExpiry.open()" required [disabled]="parent.userInfo.driving.dateOfIssue===null">
          <mat-datepicker-toggle matSuffix [for]="dateOfExpiry"></mat-datepicker-toggle>
          <mat-datepicker #dateOfExpiry touchUi [startAt]="startDate" startView="multi-year"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Ausstellungsbehörde (4c)</mat-label>
          <input matInput [(ngModel)]="parent.userInfo.driving.issuingAuthority" [matAutocomplete]="auto" required>
        </mat-form-field>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option value="Landratsamt München">Landratsamt München</mat-option>
          <mat-option value="Landeshauptstadt München">Landeshauptstadt München</mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-lg-12 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Führerscheinnummer (5)</mat-label>
          <input matInput [(ngModel)]="parent.userInfo.driving.dlNumber" required>
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-12">
        <mat-form-field id="newExpiryDate" *ngIf="!parent.env.mobile" class="input-full-width" appearance="outline">
          <mat-label>Ablaufdatum {{extendClass}}</mat-label>
          <input matInput [matDatepicker]="newExpiryDate" [min]="today" [max]="expiryClass"
            placeholder="Ablaufdatum Führerschein" [value]="extendDate" (dateChange)="ondateOfExtendChange($event)"
            (change)="onextendDateChange($event)" (focus)="newExpiryDate.open()" required
            [disabled]="parent.userInfo.driving.dateOfIssue===null">
          <mat-datepicker-toggle matSuffix [for]="newExpiryDate"></mat-datepicker-toggle>
          <mat-datepicker #newExpiryDate [startAt]="startDate" startView="multi-year"></mat-datepicker>
        </mat-form-field>
        <mat-form-field id="newExpiryDate" *ngIf="parent.env.mobile" class="input-full-width" appearance="outline">
          <mat-label>Ablaufdatum {{extendClass}}</mat-label>
          <input matInput [matDatepicker]="newExpiryDate" [min]="today" [max]="expiryClass"
            placeholder="Ablaufdatum Führerschein" [value]="extendDate" (dateChange)="ondateOfExtendChange($event)"
            (change)="onextendDateChange($event)" (focus)="newExpiryDate.open()" required
            [disabled]="parent.userInfo.driving.dateOfIssue===null">
          <mat-datepicker-toggle matSuffix [for]="newExpiryDate"></mat-datepicker-toggle>
          <mat-datepicker #newExpiryDate touchUi [startAt]="startDate" startView="multi-year"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!licenceUploadValid">
      Bitte erst aktuelle Führerscheinbilder hochladen.
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: space-between" *ngIf="licenceUploadValid">
    <button mat-mini-fab mat-dialog-close color="warn"><mat-icon>close</mat-icon></button>
    <button mat-mini-fab mat-dialog-close
      [disabled]="extendDate===null || parent.userInfo.driving.dateOfIssue===null || parent.userInfo.driving.issuingAuthority === '' || parent.userInfo.driving.dlNumber === ''"
      (click)="saveLicenceDate()" color="success"><mat-icon>done</mat-icon></button>
  </mat-dialog-actions>
  <mat-dialog-actions style="align-items:center;justify-content: space-between" *ngIf="!licenceUploadValid"
    layout="column" layout-align="space-between center">
    <button mat-raised-button mat-dialog-close color="primary" (click)="openLicencePictureDialog()">Führerschein
      Bilder
      <mat-icon>add_a_photo
      </mat-icon>
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #licenceInfo>
  <mat-dialog-header>
    <div class="clearfix">
      <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
        <mat-icon>close</mat-icon>
      </a>
      <h2 mat-dialog-title class="float-left">Führerscheindaten bearbeiten</h2>
    </div>
  </mat-dialog-header>
  <mat-dialog-content>
    <div *ngIf="licenceUploadValid">
      <div class="col-lg-12 col-12">
        <mat-form-field id="dateOfIssue" *ngIf="!parent.env.mobile" class="input-full-width" appearance="outline">
          <mat-label>Ausstellungsdatum (4a)</mat-label>
          <input matInput [matDatepicker]="dateOfIssue" placeholder="Ausstellungsdatum Führerschein"
            [min]="parent.age18Date" [value]="parent.userInfo.driving.dateOfIssue"
            (dateChange)="ondateOfIssueChange($event)" [max]="today" (change)="onissueDateChange($event)"
            (focus)="dateOfIssue.open()" required>
          <mat-datepicker-toggle matSuffix [for]="dateOfIssue"></mat-datepicker-toggle>
          <mat-datepicker #dateOfIssue [startAt]="startDate" startView="multi-year"></mat-datepicker>
        </mat-form-field>
        <mat-form-field id="dateOfIssue" *ngIf="parent.env.mobile" class="input-full-width" appearance="outline">
          <mat-label>Ausstellungsdatum (4a)</mat-label>
          <input matInput [matDatepicker]="dateOfIssue" placeholder="Ausstellungsdatum Führerschein"
            [min]="parent.age18Date" [value]="parent.userInfo.driving.dateOfIssue"
            (dateChange)="ondateOfIssueChange($event)" [max]="today" (change)="onissueDateChange($event)"
            (focus)="dateOfIssue.open()" required>
          <mat-datepicker-toggle matSuffix [for]="dateOfIssue"></mat-datepicker-toggle>
          <mat-datepicker #dateOfIssue touchUi [startAt]="startDate" startView="multi-year"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-12">
        <mat-form-field id="dateOfExpiry" *ngIf="!parent.env.mobile" class="input-full-width" appearance="outline">
          <mat-label>Ablaufdatum (4b)</mat-label>
          <input matInput [matDatepicker]="dateOfExpiry" [min]="parent.userInfo.driving.dateOfIssue"
            [max]="expiryLicence" placeholder="Ablaufdatum Führerschein" [value]="parent.userInfo.driving.dateOfExpiry"
            (dateChange)="ondateOfExpiryChange($event)" (change)="onexpiryDateChange($event)"
            (focus)="dateOfExpiry.open()" required [disabled]="parent.userInfo.driving.dateOfIssue===null">
          <mat-datepicker-toggle matSuffix [for]="dateOfExpiry"></mat-datepicker-toggle>
          <mat-datepicker #dateOfExpiry [startAt]="startDate" startView="multi-year"></mat-datepicker>
        </mat-form-field>
        <mat-form-field id="dateOfExpiry" *ngIf="parent.env.mobile" class="input-full-width" appearance="outline">
          <mat-label>Ablaufdatum (4b)</mat-label>
          <input matInput [matDatepicker]="dateOfExpiry" [min]="parent.userInfo.driving.dateOfIssue"
            [max]="expiryLicence" placeholder="Ablaufdatum Führerschein" [value]="parent.userInfo.driving.dateOfExpiry"
            (dateChange)="ondateOfExpiryChange($event)" (change)="onexpiryDateChange($event)"
            (focus)="dateOfExpiry.open()" required [disabled]="parent.userInfo.driving.dateOfIssue===null">
          <mat-datepicker-toggle matSuffix [for]="dateOfExpiry"></mat-datepicker-toggle>
          <mat-datepicker #dateOfExpiry touchUi [startAt]="startDate" startView="multi-year"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-lg-12 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Ausstellungsbehörde (4c)</mat-label>
          <input matInput [(ngModel)]="parent.userInfo.driving.issuingAuthority" [matAutocomplete]="auto" required>
        </mat-form-field>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option value="Landratsamt München">Landratsamt München</mat-option>
          <mat-option value="Landeshauptstadt München">Landeshauptstadt München</mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-lg-12 col-12">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Führerscheinnummer (5)</mat-label>
          <input matInput [(ngModel)]="parent.userInfo.driving.dlNumber" required>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!licenceUploadValid">
      Bitte erst aktuelle Führerscheinbilder hochladen.
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: space-between" *ngIf="licenceUploadValid">
    <button mat-mini-fab mat-dialog-close color="warn"><mat-icon>close</mat-icon></button>
    <button mat-mini-fab mat-dialog-close
      [disabled]="parent.userInfo.driving.dateOfIssue===null || parent.userInfo.driving.issuingAuthority === '' || parent.userInfo.driving.dlNumber === ''"
      (click)="saveDriving()" color="success"><mat-icon>done</mat-icon></button>
  </mat-dialog-actions>
  <mat-dialog-actions style="align-items:center;justify-content: space-between" *ngIf="!licenceUploadValid"
    layout="column" layout-align="space-between center">
    <button mat-raised-button mat-dialog-close color="primary" (click)="openLicencePictureDialog()">Führerschein
      Bilder
      <mat-icon>add_a_photo
      </mat-icon>
    </button>
  </mat-dialog-actions>
</ng-template>