<div class="row">
    <div class="col-lg-8 col-12 offset-lg-2">
        <h3 class="section-header">{{firstname}} {{lastname}}
        </h3>
        <mat-card>
            <mat-card-content>
                <a onClick="javascript:window.history.back()" mat-mini-fab color="primary" class="pageBack">
                    <mat-icon>keyboard_backspace</mat-icon>
                </a>
                <a mat-mini-fab color="primary" class="pageNext" (click)="openMenu()"
                    *ngIf="_login.hasPermission('admin-user-drivingperm-edit')">
                    <mat-icon>apps</mat-icon>
                </a>
                <div class="row">
                    <div class="col-lg-12 col-12" *ngIf="showPictures">
                        <div class="colum has-text-centered row">
                            <div *ngFor="let image of licencePictures; let i=index" class="img-row col-lg-6 col-12">
                                <img class="img img-frame" [src]="image.src" (click)="open(i)"
                                    *ngIf="!image.src.includes('default')" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-12" *ngIf="!showPictures">
                        <span style="margin-left: 15px; margin-right: 35px;">Es wurden noch keine Führerscheinbilder
                            hochgeladen.</span>
                        <button mat-raised-button color="primary" (click)="openLicencePictureDialog()"
                            *ngIf="_login.hasPermission('admin-user-drivingperm-edit')">
                            <mat-icon>add_a_photo</mat-icon>Führerscheinbilder hochladen
                        </button>
                    </div>
                    <div class="col-lg-12 col-12" *ngIf="!dienstfahrerlaubnis_signed && showPictures"
                        style="margin-top: 15px;">
                        <span style="margin-left: 15px; margin-right: 35px;">Es wurde noch keine unterschriebene
                            Dienstfahrerlaubnis hochgeladen.</span>
                        <button mat-raised-button color="primary" (click)="openDienstfahrerlaubnisDialog()"
                            *ngIf="_login.hasPermission('admin-user-drivingperm-edit')">
                            <mat-icon>gesture</mat-icon>Dienstfahrerlaubnis unterschreiben
                        </button>
                    </div>
                    <div class="col-lg-12 col-12" *ngIf="!gabelstapler_signed && licencePictures.length > 2"
                        style="margin-top: 15px;">
                        <span style="margin-left: 15px; margin-right: 35px;">Es wurde noch kein Fahrautrag für
                            Flurförderfahrzeuge hochgeladen.</span>
                        <button mat-raised-button color="primary" (click)="openGabelstaplerDialog()"
                            *ngIf="_login.hasPermission('admin-user-drivingperm-edit')">
                            <mat-icon>gesture</mat-icon>Beauftragung Flurförderfahrzeuge unterschreiben
                        </button>
                    </div>
                    <div class="col-lg-12 col-12" *ngIf="!fahrzeugkraene_signed" style="margin-top: 15px;">
                        <span style="margin-left: 15px; margin-right: 35px;">Es wurde noch keine Beauftragung für
                            Fahrzeugkräne
                            hochgeladen.</span>
                        <button mat-raised-button color="primary" (click)="openFahrzeugkraeneDialog()"
                            *ngIf="_login.hasPermission('admin-user-drivingperm-edit')">
                            <mat-icon>gesture</mat-icon>Beauftragung Fahrzeugkräne unterschreiben
                        </button>
                    </div>
                    <mat-tab-group dynamicHeight style="margin-top: 15px;" class="col-lg-12 col-12"
                        *ngIf="dienstfahrerlaubnis_signed || gabelstapler_signed || fahrzeugkraene_signed"
                        mat-align-tabs="center" (selectedTabChange)="loadPdf($event)">
                        <mat-tab label="Dienstfahrerlaubnis" *ngIf="dienstfahrerlaubnis_signed">
                        </mat-tab>
                        <mat-tab label="Beauftragung Flurförderfahrzeuge" *ngIf="gabelstapler_signed">
                        </mat-tab>
                        <mat-tab label="Beauftragung Fahrzeugkräne" *ngIf="fahrzeugkraene_signed">
                        </mat-tab>
                    </mat-tab-group>

                    <div class="col-12 pdf" id="pdf" [ngClass]="env.mobile ? 'mobilepdf' : ''"></div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<ng-template #dienstfahrerlaubnisDialog>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">Dienstfahrerlaubnis unterschreiben</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content>
        <div class="row">
            <div class="col-lg-6 col-12" style="margin-bottom: 15px;">
                <a mat-raised-button color="primary" [href]="dienstfahrerlaubnis_unsigned" target="_blank"
                    class="uploadLabel">Dienstfahrerlaubnis
                    herunterladen</a>
            </div>
            <div class="col-lg-6 col-12">
                <label for="uploadDienstfahrerlaubnis">
                    <span class="uploadLabel">Dienstfahrerlaubnis hochladen</span>
                </label>
                <input type="file" [(ngModel)]="dienstfahrerlaubnisUpload" (change)="uploadDienstfahrerlaubnis($event)"
                    style="display:none;" id="uploadDienstfahrerlaubnis" accept="application/pdf" />
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #gabelstaplerDialog>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">Beauftragung Flurförderfahrzeuge</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content>
        <div class="row">
            <div class="col-lg-6 col-12" style="margin-bottom: 15px;">
                <a mat-raised-button color="primary" [href]="gabelstapler_unsigned" target="_blank"
                    class="uploadLabel">Beauftragung
                    herunterladen</a>
            </div>
            <div class="col-lg-6 col-12">
                <label for="uploadGabelstapler">
                    <span class="uploadLabel">Beauftragung hochladen</span>
                </label>
                <input type="file" [(ngModel)]="gabelstaplerUpload" (change)="uploadGabelstapler($event)"
                    style="display:none;" id="uploadGabelstapler" accept="application/pdf" />
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #fahrzeugkraeneDialog>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">Beauftragung Fahrzeugkräne</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content>
        <div class="row">
            <div class="col-lg-6 col-12" style="margin-bottom: 15px;">
                <a mat-raised-button color="primary" [href]="fahrzeugkraene_unsigned" target="_blank"
                    class="uploadLabel">Beauftragung
                    herunterladen</a>
            </div>
            <div class="col-lg-6 col-12">
                <label for="uploadfahrzeugkraene">
                    <span class="uploadLabel">Beauftragung hochladen</span>
                </label>
                <input type="file" [(ngModel)]="fahrzeugkraeneUpload" (change)="uploadfahrzeugkraene($event)"
                    style="display:none;" id="uploadfahrzeugkraene" accept="application/pdf" />
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #licenseDialog>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">Führerscheinbilder Upload</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content>
        <div class="row">
            <div class="col-lg-6 col-12 container" *ngIf="!frontLoading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <img src="{{licencePictures[0].src}}" class="image" />
                <div class="upload">
                    <label for="uploadFront">
                        <span class="uploadLabel">Vorderseite hochladen</span>
                    </label>
                    <input type="file" [(ngModel)]="frontFileUrl" (change)="uploadFront($event)" style="display:none;"
                        id="uploadFront" accept="image/x-png,image/jpeg,image/jpg" />
                </div>
            </div>
            <div class="col-lg-6 col-12 container" *ngIf="frontLoading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <mat-spinner color="primary"></mat-spinner>
            </div>
            <div class="col-lg-6 col-12 container" *ngIf="!backLoading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <img src="{{licencePictures[1].src}}" class="image" />
                <div class="upload">
                    <label for="uploadBack">
                        <span class="uploadLabel">Rückseite hochladen</span>
                    </label>
                    <input type="file" [(ngModel)]="backFileUrl" (change)="uploadBack($event)" style="display:none;"
                        id="uploadBack" accept="image/x-png,image/jpeg,image/jpg" />
                </div>
            </div>
            <div class="col-lg-6 col-12 container" *ngIf="backLoading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <mat-spinner color="primary"></mat-spinner>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #resizeFront>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">Vorderseite zuschneiden</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content [ngStyle]="env.mobile ? 'max-width: 250px' : 'min-width: 400px'">
        <mat-progress-bar mode="query" *ngIf="imageFrontLoading"></mat-progress-bar>
        <image-cropper [imageChangedEvent]="imageFrontChangedEvent" [maintainAspectRatio]="false" format="png"
            [canvasRotation]="turn" (imageLoaded)="imageFrontLoaded()" (imageCropped)="imageFrontCropped($event)"
            (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: space-between">
        <button mat-mini-fab mat-dialog-close color="warn" (click)="closeReset()"><mat-icon>close</mat-icon></button>
        <div>
            <button mat-mini-fab color="accent" style="margin-left: 10px;" [disabled]="imageFrontLoading"
                (click)="turnLeft()"><mat-icon>undo</mat-icon></button>
            <button mat-mini-fab color="accent" style="margin-right: 10px;" [disabled]="imageFrontLoading"
                (click)="turnRight()"><mat-icon>redo</mat-icon></button>
        </div>
        <button mat-mini-fab mat-dialog-close (click)="saveFront()"
            color="success"><mat-icon>done</mat-icon></button>
    </mat-dialog-actions>
</ng-template>

<ng-template #resizeBack>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">Rückseite zuschneiden</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content [ngStyle]="env.mobile ? 'max-width: 250px' : 'min-width: 400px'">
        <mat-progress-bar mode="query" *ngIf="imageBackLoading"></mat-progress-bar>
        <image-cropper [imageChangedEvent]="imageBackChangedEvent" [maintainAspectRatio]="false" format="png"
            [canvasRotation]="turn" (imageLoaded)="imageBackLoaded()" (imageCropped)="imageBackCropped($event)"
            (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: space-between">
        <button mat-mini-fab mat-dialog-close color="warn" (click)="closeReset()"><mat-icon>close</mat-icon></button>
        <div>
            <button mat-mini-fab color="accent" style="margin-left: 10px;" [disabled]="imageBackLoading"
                (click)="turnLeft()"><mat-icon>undo</mat-icon></button>
            <button mat-mini-fab color="accent" style="margin-right: 10px;" [disabled]="imageBackLoading"
                (click)="turnRight()"><mat-icon>redo</mat-icon></button>
        </div>
        <button mat-mini-fab mat-dialog-close (click)="saveBack()"
            color="success"><mat-icon>done</mat-icon></button>
    </mat-dialog-actions>
</ng-template>

<ng-template #FFFDialog>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">Staplerschein Upload</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content>
        <div class="row">
            <div class="col-lg-6 col-12 container" *ngIf="!FFFfrontLoading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <img src="{{licencePictures[2].src}}" class="image" />
                <div class="upload">
                    <label for="uploadFront">
                        <span class="uploadLabel">Vorderseite hochladen</span>
                    </label>
                    <input type="file" [(ngModel)]="FFFfrontFileUrl" (change)="FFFuploadFront($event)"
                        style="display:none;" id="uploadFront" accept="image/x-png,image/jpeg,image/jpg" />
                </div>
            </div>
            <div class="col-lg-6 col-12 container" *ngIf="frontLoading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <mat-spinner color="primary"></mat-spinner>
            </div>
            <div class="col-lg-6 col-12 container" *ngIf="!FFFbackLoading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <img src="{{licencePictures[3].src}}" class="image" />
                <div class="upload">
                    <label for="uploadBack">
                        <span class="uploadLabel">Rückseite hochladen</span>
                    </label>
                    <input type="file" [(ngModel)]="FFFbackFileUrl" (change)="FFFuploadBack($event)"
                        style="display:none;" id="uploadBack" accept="image/x-png,image/jpeg,image/jpg" />
                </div>
            </div>
            <div class="col-lg-6 col-12 container" *ngIf="backLoading" layout="column"
                layout-align="space-between center" style="display:flex;justify-content:center;align-items:center;">
                <mat-spinner color="primary"></mat-spinner>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #FFFresizeFront>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">Vorderseite zuschneiden</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content [ngStyle]="env.mobile ? 'max-width: 250px' : 'min-width: 400px'">
        <mat-progress-bar mode="query" *ngIf="FFFimageFrontLoading"></mat-progress-bar>
        <image-cropper [imageChangedEvent]="FFFimageFrontChangedEvent" [maintainAspectRatio]="false" format="png"
            [canvasRotation]="turn" (imageLoaded)="FFFimageFrontLoaded()" (imageCropped)="FFFimageFrontCropped($event)"
            (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: space-between">
        <button mat-mini-fab mat-dialog-close color="warn" (click)="closeReset()"><mat-icon>close</mat-icon></button>
        <div>
            <button mat-mini-fab color="accent" style="margin-left: 10px;" [disabled]="FFFimageFrontLoading"
                (click)="turnLeft()"><mat-icon>undo</mat-icon></button>
            <button mat-mini-fab color="accent" style="margin-right: 10px;" [disabled]="FFFimageFrontLoading"
                (click)="turnRight()"><mat-icon>redo</mat-icon></button>
        </div>
        <button mat-mini-fab mat-dialog-close (click)="FFFsaveFront()"
            color="success"><mat-icon>done</mat-icon></button>
    </mat-dialog-actions>
</ng-template>

<ng-template #FFFresizeBack>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">Rückseite zuschneiden</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content [ngStyle]="env.mobile ? 'max-width: 250px' : 'min-width: 400px'">
        <mat-progress-bar mode="query" *ngIf="FFFimageBackLoading"></mat-progress-bar>
        <image-cropper [imageChangedEvent]="FFFimageBackChangedEvent" [maintainAspectRatio]="false" format="png"
            [canvasRotation]="turn" (imageLoaded)="FFFimageBackLoaded()" (imageCropped)="FFFimageBackCropped($event)"
            (loadImageFailed)="FFFloadImageFailed()">
        </image-cropper>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: space-between">
        <button mat-mini-fab mat-dialog-close color="warn" (click)="closeReset()"><mat-icon>close</mat-icon></button>
        <div>
            <button mat-mini-fab color="accent" style="margin-left: 10px;" [disabled]="FFFimageBackLoading"
                (click)="turnLeft()"><mat-icon>undo</mat-icon></button>
            <button mat-mini-fab color="accent" style="margin-right: 10px;" [disabled]="FFFimageBackLoading"
                (click)="turnRight()"><mat-icon>redo</mat-icon></button>
        </div>
        <button mat-mini-fab mat-dialog-close (click)="FFFsaveBack()"
            color="success"><mat-icon>done</mat-icon></button>
    </mat-dialog-actions>
</ng-template>

<ng-template #menu>
    <div class="text-center">
        <br>
        <a (click)="dismissMenu()" mat-icon-button class="closeButton">
            <mat-icon>close</mat-icon>
        </a>
        <mat-nav-list>
            <mat-list-item (click)="openLicencePictureDialog()">
                <span matLine>Führerscheinbilder hochladen</span>
                <mat-icon class="float-right">add_a_photo</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="openFFFPictureDialog()">
                <span matLine>Staplerscheinbilder hochladen</span>
                <mat-icon class="float-right">add_a_photo</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="openDienstfahrerlaubnisDialog()" *ngIf="showPictures">
                <span matLine>Dienstfahrerlaubnis unterschreiben</span>
                <mat-icon class="float-right">gesture</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="openGabelstaplerDialog()" *ngIf="licencePictures.length > 2">
                <span matLine>Beauftragung Flurförderfahrzeuge unterschreiben</span>
                <mat-icon class="float-right">gesture</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="openFahrzeugkraeneDialog()">
                <span matLine>Beauftragung Fahrzeugkräne unterschreiben</span>
                <mat-icon class="float-right">gesture</mat-icon>
            </mat-list-item>
        </mat-nav-list>
    </div>
</ng-template>

<ng-template #signotecSign>
    <mat-dialog-header>
        <div class="clearfix">
            <a mat-icon-button mat-dialog-close cdkFocusInitial class="closeButtonDialog float-right">
                <mat-icon>close</mat-icon>
            </a>
            <h2 mat-dialog-title class="float-left">{{ signotecDocumentTyp }} unterschreiben</h2>
        </div>
    </mat-dialog-header>
    <mat-dialog-content>
        <div *ngIf="!viewed" class="pdf" id="pdfSign" [ngClass]="env.mobile ? 'mobilepdf' : ''">
        </div>
        <div class="row" *ngIf="viewed">
            <div class="col-12 col-md-6 col-lg-6" style="text-align: center; min-width: 250px;">
                <h4>Ausbilder:<br />{{env.userInfo.firstname}} {{env.userInfo.lastname}} <br /><br />
                    <button mat-raised-button color="primary" [disabled]="signotecWorking"
                        (click)="openSignature('Unterschrift Ausbilder', 'ausbilder_picture')">unterschreiben</button>
                </h4>
                <img class="unterschrift" *ngIf="ausbilder_picture" [src]="ausbilder_picture">
            </div>
            <div class="col-12 col-md-6 col-lg-6" style="text-align: center; min-width: 250px;">
                <h4>Maschinist:<br />{{firstname}} {{lastname}} <br /><br />
                    <button mat-raised-button color="primary" [disabled]="signotecWorking"
                        (click)="openSignature('Unterschrift Maschinist', 'maschinist_picture')">unterschreiben</button>
                </h4>
                <img class="unterschrift" *ngIf="maschinist_picture" [src]="maschinist_picture">
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button color="primary" *ngIf="!viewed" style="width: 100%;"
            (click)="viewed = true">Weiter</button>
        <button mat-raised-button color="success" [disabled]="!ausbilder_picture || !maschinist_picture"
            *ngIf="viewed && signotecDocumentTyp === 'Dienstfahrerlaubnis'" style="width: 100%;"
            (click)="saveDienstfahrerlaubnisSigned()">Speichern <mat-icon>save
            </mat-icon></button>
        <button mat-raised-button color="success" [disabled]="!ausbilder_picture || !maschinist_picture"
            *ngIf="viewed && signotecDocumentTyp === 'Beauftragung Flurförderfahrzeuge'" style="width: 100%;"
            (click)="saveFlurfoerderfahrzeugeSigned()">Speichern <mat-icon>save
            </mat-icon></button>
        <button mat-raised-button color="success" [disabled]="!ausbilder_picture || !maschinist_picture"
            *ngIf="viewed && signotecDocumentTyp === 'Beauftragung Fahrzeugkräne'" style="width: 100%;"
            (click)="saveFahrzeugkraeneSigned()">Speichern <mat-icon>save
            </mat-icon></button>
    </mat-dialog-actions>
</ng-template>