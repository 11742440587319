<mat-spinner *ngIf="isLoading" color="accent" class="loading-spinner-center" [diameter]="200"></mat-spinner>

<div class="container">
  <div class="col-lg-10 col-12 offset-lg-1" *ngIf="!isLoading">
    <h3 class="section-header">Material
      <span *ngIf="!materialId" class="ms-2">erstellen</span>
      <span *ngIf="materialId" class="ms-2">bearbeiten</span>
    </h3>
    <mat-card class="mb-4">
      <mat-card-content>
        <a routerLink="../../" mat-mini-fab color="primary" class="pageBack">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <a (click)="openMenu()" mat-mini-fab color="primary" class="pageNext" *ngIf="materialId">
          <mat-icon>apps</mat-icon>
        </a>

        <form [formGroup]="group">
          <div class="row">
            <div class="col-12" [class.col-lg-6]="materialId">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Name oder EAN</mat-label>
                <input matInput type="text" formControlName="name" />
                <button mat-icon-button matSuffix (click)="openScanner('name')">
                  <mat-icon>qr_code</mat-icon>
                </button>
                <mat-error *ngIf="group.get('name').invalid">Name ist ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>

            <div class="col-12" [class.col-lg-6]="materialId">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Seriennummer</mat-label>
                <input matInput type="text" formControlName="serialNumber" />
                <button mat-icon-button matPrefix (click)="openNFCScanner('serialNumber')">
                  <mat-icon>nfc</mat-icon>
                </button>
                <button mat-icon-button matSuffix (click)="openScanner('serialNumber')">
                  <mat-icon>qr_code</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12" *ngIf="materialId">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" required="true">
                  <mat-option value="active">aktiv</mat-option>
                  <mat-option value="retired">außer Dienst</mat-option>
                  <mat-option value="stolen">gestohlen</mat-option>
                  <mat-option value="repairing">in Reparatur</mat-option>
                  <mat-option value="lost">verloren</mat-option>
                </mat-select>
                <mat-error *ngIf="group.get('status').invalid">Status ist ein Pflichtfeld!</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Kategorie</mat-label>
                <mat-select formControlName="categoryId">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="categoryFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let category of filteredCategories | async" [value]="category.id">{{ category.name
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Produktart</mat-label>
                <mat-select formControlName="option">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="categoryOptionFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let option of filteredCategoriesOptions | async" [value]="option">{{ option
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Hersteller</mat-label>
                <input matInput formControlName="manufacturer" />
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Lieferant</mat-label>
                <input matInput formControlName="supplier" />
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Anschaffungspreis</mat-label>
                <mat-icon matSuffix>euro</mat-icon>
                <input matInput formControlName="purchasePrice" type="number" />
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Indienststellung</mat-label>
                <input matInput formControlName="commissioning" [max]="today" [matDatepicker]="picker" />
                <mat-hint>TT.MM.JJJJ</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12" [hidden]="group.get('userId')?.value">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Lagerplatz zuweisen</mat-label>
                <mat-select formControlName="storageId" (selectionChange)="evaluateValidatorStorageUser()">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="storageFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option [value]="null">-- Kein Lagerplatz --</mat-option>
                  <mat-option *ngFor="let storage of filteredStorage | async" [value]="storage.id">{{(storage.vehicle ??
                    storage.location) != null ? (storage.vehicle ?? storage.location) + ' - ' : ''}}{{ storage.name
                    }}</mat-option>
                </mat-select>
                <mat-error *ngIf="group.get('storageId').invalid">Lagerplatz oder Person muss gesetzt sein!</mat-error>
              </mat-form-field>
            </div>

            <div class="col-12" [hidden]="group.get('storageId')?.value">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Person zuweisen</mat-label>
                <mat-select formControlName="userId" (selectionChange)="evaluateValidatorStorageUser()">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="userFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option [value]="null">-- Keine Person zugewiesen --</mat-option>
                  <mat-option *ngFor="let user of filteredUser | async" [value]="user.id">{{ user.id + ' ' +
                    user.firstname + ' ' +
                    user.lastname
                    }}</mat-option>
                </mat-select>
                <mat-error *ngIf="group.get('userId').invalid">Person oder Lagerplatz muss gesetzt sein!</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12" *ngIf="consumables">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Bestandsmenge</mat-label>
                <input matInput formControlName="stock" [min]="0.1" type="number" />
                <span matSuffix>{{ unit }}</span>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Garantie bis</mat-label>
                <input matInput formControlName="warranty" [matDatepicker]="warrantyPicker" />
                <mat-hint>TT.MM.JJJJ</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="warrantyPicker"></mat-datepicker-toggle>
                <mat-datepicker #warrantyPicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Ablaufdatum</mat-label>
                <input matInput formControlName="expiration" [min]="today" [matDatepicker]="expirationPicker" />
                <mat-hint>TT.MM.JJJJ</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="expirationPicker"></mat-datepicker-toggle>
                <mat-datepicker #expirationPicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Erinnerungsdatum</mat-label>
                <input matInput formControlName="rememberDate" [min]="today" [matDatepicker]="pickerReminder" />
                <mat-hint>TT.MM.JJJJ</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="pickerReminder"></mat-datepicker-toggle>
                <mat-datepicker #pickerReminder></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="outline" class="input-full-width mt-2">
                <mat-label>Bemerkung</mat-label>
                <textarea matInput formControlName="comment" cdkTextareaAutosize></textarea>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div class="clearfix">
          <button mat-raised-button class="float-right" color="accent" [disabled]="group.invalid" (click)="saveData()">
            <div class="clearfix submit-button">
              <mat-icon class="me-3 mt-1 float-left" [hidden]="!isSaving">
                <mat-spinner diameter="20"></mat-spinner>
              </mat-icon>
              <span class="me-1 float-left">Speichern</span>
              <mat-icon class="float-right">save</mat-icon>
            </div>
          </button>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="me-2">attach_file</mat-icon> Anhänge / Bedienungsanleitungen ({{ fileList.length }})
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list class="input-full-width mt-3 mb-2" *ngIf="fileList.length > 0">
          <mat-list-item *ngFor="let file of fileList">
            <div class="clearfix input-full-width">
              <span class="float-left" *ngIf="!file.id">{{file.name}}</span>
              <a *ngIf="file.id" class="float-left" [target]="isPWA ? '_self' : '_blank'"
                href="{{baseURL}}admin/material/equipment/{{this.materialId}}/file/{{file.id}}?sessionToken={{_login.getSessionToken()}}">{{file.name}}</a>
              <div class="float-right">
                <div *ngIf="!file.id && !file.error">
                  <span class="float-left me-3">Wird hochgeladen ...</span>
                  <mat-spinner [diameter]="30" style="margin-top: -8px" class="float-right"></mat-spinner>
                </div>
                <div *ngIf="file.error">
                  <span class="float-left me-3" style="color: red;">Fehlerhaft</span>
                  <mat-icon style="color: red;" class="float-right">close</mat-icon>
                </div>
                <div *ngIf="file.id">
                  <button mat-icon-button (click)="deleteAttachement(file)" [disabled]="file.isDeleting">
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-list-item>
        </mat-list>

        <input #FileSelectInputDialog type="file" multiple style="display: none" (change)="uploadAttachments($event)"
          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" />
        <button mat-raised-button color="primary" (click)="attachFile()">
          <mat-icon class="me-2">attach_file_add</mat-icon>
          Anhang hinzufügen
        </button>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Prüfungen ({{ checkCycles.length }})
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list>
          <mat-list-item *ngFor="let check of checkCycles" [routerLink]="['../../../checks/cycles/details/', check.id]">
            <span matListItemTitle>{{check.name}} 
              <span *ngIf="check.status == 'open'">(fällig am {{dayjs(check.date).format('DD.MM.YYYY')}})</span>
              <span *ngIf="check.status != 'open'">(abgeschlossen am {{dayjs(check.completionDate).format('DD.MM.YYYY')}})</span>
            </span>
            <span matListItemLine class="check-description" [innerHTML]="check.comments"></span>

            <mat-icon matListItemIcon *ngIf="check.status == 'open'"
              matTooltip="Diese Prüfung steht noch aus">radio_button_unchecked</mat-icon>
            <mat-icon matListItemIcon class="text-success" *ngIf="check.status == 'successful'"
              matTooltip="Diese Prüfung wurde abgeschlossen">check_circle</mat-icon>
            <mat-icon matListItemIcon class="text-danger" *ngIf="check.status == 'failed'"
              matTooltip="Diese Prüfung ist durchgefallen!">unpublished</mat-icon>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<ng-template #menu>
  <div class="clearfix">
    <a (click)="_bottomSheet.dismiss()" mat-icon-button class="closeButton">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-nav-list class="mt-4">
    <mat-list-item (click)="_bottomSheet.dismiss(); openQRCode()">
      <mat-icon matListItemIcon>qr_code</mat-icon>
      <a matListItemLine>QR Code drucken</a>
    </mat-list-item>
    <mat-list-item (click)="_bottomSheet.dismiss(); sign()" *ngIf="this.group.get('userId').value !== null">
      <mat-icon matListItemIcon>gesture</mat-icon>
      <a matListItemLine>Materialausgabeschein</a>
    </mat-list-item>
    <mat-list-item (click)="_bottomSheet.dismiss(); info()"
      *ngIf="this.group.get('comment').value !== null && this.group.get('comment').value !== ''">
      <mat-icon matListItemIcon>info</mat-icon>
      <a matListItemLine>Informationsschein</a>
    </mat-list-item>
  </mat-nav-list>
</ng-template>

<ng-template #qrcode>
  <div class="text-center mt-3" *ngIf="materialId">
    <qrcode [qrdata]="'DATA-' + materialId" errorCorrectionLevel="L" id="qrcode-data"></qrcode>
  </div>
</ng-template>