import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator as MatPaginator, MatPaginatorIntl as MatPaginatorIntl, PageEvent as PageEvent } from '@angular/material/paginator';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { EventOverview, Group } from '@ffo/mitgliederbereich-types';
import { DateTime } from 'luxon';
import { getHostUrl } from 'src/app/util/config';
import { RouteGuard } from 'src/app/util/guards/route.guard';
import { Login } from 'src/app/util/login';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['../event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    RouteGuard,
  ],
})
export class EventListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  env = environment;
  constructor(public _login: Login,
    private _http: HttpClient,
    private _ref: ChangeDetectorRef,
  ) { }

  dataSource = new MatTableDataSource();
  eventList: EventOverview[] = [];
  displayedColumns: string[] = ['title', 'date', 'type', 'response', 'actions'];

  groupList: Group[] = [];

  groupsFilter: any[] = [];
  searchFilter: string = '';
  pageIndex: number = 0;
  pageSize: number = 20;

  showLoader = true;

  ngOnInit(): void {
    this.loadEvents();
    this.fetchDataInfo();
    if (this.env.mobile) {
      this.displayedColumns = ['title', 'date', 'type'];
    } else if (this._login.hasPermission('admin-event-view-all')) {
      this.displayedColumns = ['title', 'date', 'type', 'response', 'actions', 'visibility'];
    }
  }

  applyHashLocation() {
    this.groupsFilter = this.getSessionStorage().groups;
    this.searchFilter = this.getSessionStorage().search;
    this.pageIndex = this.getSessionStorage().page;
    this.pageSize = this.getSessionStorage().size;
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, groups: this.groupsFilter });
    this._ref.detectChanges();
  }

  getSessionStorage() {
    const item = JSON.parse(sessionStorage.getItem('eventFilter-'  + this._login.getUserID()) ?? '{}');

    return {
      search: item?.search ?? '',
      groups: item?.groups ?? [],
      size: item?.size ?? 10,
      page: item?.page ?? 0
    }
  }

  setHashLocation() {
    sessionStorage.setItem('eventFilter-' + this._login.getUserID(), JSON.stringify({
      search: this.searchFilter, page: this.pageIndex, groups: this.groupsFilter, size: this.pageSize
    }));
  }

  loadEvents() {
    this._http.get<EventOverview[]>(`${getHostUrl()}events/all`)
      .subscribe((data: EventOverview[]) => {
        this.eventList = data;
        this.dataSource = new MatTableDataSource<EventOverview>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item: EventOverview, property) => {
          switch (property) {
            case 'date':
              const date = DateTime.fromFormat(item.startDate, 'dd.MM.yy').setZone('Europe/Berlin');
              if (item.allDay) {
                return date.setLocale('de').toJSDate();
              }
              const time = DateTime.fromFormat(item.startTime, 'HH:mm').setZone('Europe/Berlin');
              return date.set({ hour: time.hour, minute: time.minute }).setLocale('de').toJSDate();
            default: return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.filter();
        this.applyFilter(decodeURI(this.searchFilter));
        this.showLoader = false;
      });
  }

  getDate(d: string) {
    return DateTime.fromFormat(d, 'dd.MM.yy').setLocale('de').toFormat('EEE dd.MM.yy');
  }

  filter(): (data: EventOverview, filter: string) => boolean {
    let filterFunction = function (data: EventOverview, filter: string): boolean {
      let searchTerms: { search: string; groups: number[] } = JSON.parse(filter);
      if (searchTerms.groups.length !== 0) {
        var result = true;
        searchTerms.groups.forEach(group => {
          if (!data.groups.includes(group) || data.groups.includes(-1) && !searchTerms.groups.includes(-1))
            result = false;
          if (data.title.toLowerCase().indexOf(searchTerms.search.toLowerCase()) === -1 && data.startDate.indexOf(searchTerms.search) === -1)
            result = false;
        });
        return result;
      } else {
        if (data.groups.includes(-1))
          return false;
        return (data.title.toLowerCase().indexOf(searchTerms.search.toLowerCase()) !== -1 || data.startDate.indexOf(searchTerms.search) !== -1);
      }
    };
    return filterFunction;
  }

  applyFilter(filterValue: string) {
    this.searchFilter = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, groups: this.groupsFilter });
    this.setHashLocation();
  }

  applyGroupFilter($event: MatSelectChange) {
    this.groupsFilter = $event.value;
    this.dataSource.filter = JSON.stringify({ search: this.searchFilter, groups: this.groupsFilter });
    this.setHashLocation();
  }

  applyPagination($event: PageEvent) {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.setHashLocation();
  }

  async fetchDataInfo() {
    var temp = await this._http.get<Group[]>(`${getHostUrl()}datainfo/groups?type=admin`).toPromise();
    temp.forEach(group => {
      if (group.name.includes('Gruppe ') || group.name.includes('Maschinist ') || group.name === 'MTA' || group.name === 'Atemschutz' || group.name === 'Jugend' || group.name === 'Responder' || group.name === 'Passiv' || group.name === 'Sicherheitswachen' || group.name === 'VR')
        this.groupList.push(group);
      this._ref.detectChanges();
    });
    this.groupList.push({ id: -1, name: 'Vergangene' });
    this.applyHashLocation();
  }
}
