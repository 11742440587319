import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { ConfigModule } from '../../config';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateAdapter } from 'angular-calendar';
import { CustomDateAdapterService } from '../../customDateAdapter';
import { NgxMatTimepickerLocaleService } from 'ngx-mat-timepicker';

@Component({
  selector: 'app-create-time-tracking-dialog',
  templateUrl: './create-time-tracking-dialog.component.html',
  styleUrls: ['./create-time-tracking-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapterService,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: { display: { dateInput: 'monthYearLabel' } } },
  ]
})
export class CreateTimeTrackingDialogComponent implements OnInit {

  constructor(private _http: HttpClient,
    private _ref: MatDialogRef<CreateTimeTrackingDialogComponent>,
    private _config: ConfigModule,
    private _time: NgxMatTimepickerLocaleService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    if (data.admin) {
      this.adminEdit = true;
    }
  }

  ngOnInit(): void {
    this.loadCategories();
    this.group.get('date').setValue(this.data.date);
    this._time.updateLocale('de');

    if (this.data?.item) {      
      this.timeId = this.data.item.id;
      this.group.patchValue({
        date: this.data.item.date,
        category: this.data.item.categoryID,
        in: dayjs(this.data.item.startTime, 'HH:mm:ss').format('HH:mm'),
        out: dayjs(this.data.item.endTime, 'HH:mm:ss').format('HH:mm'),
        comment: this.data.item.comment
      });      
    }

    this.categoryFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterCategories();
      });
  }

  max = new Date();
  isLoading = false;
  dayjs = dayjs;
  timeId: number;
  adminEdit = false;

  categoryList = [];
  public categoryFilterCtrl: FormControl = new FormControl();
  public categoryFilter: ReplaySubject<any> = new ReplaySubject<any>(1);

  group = new FormGroup({
    date: new FormControl(new Date(), Validators.required),
    category: new FormControl('', Validators.required),
    in: new FormControl(null, Validators.required),
    out: new FormControl(null, Validators.required),
    comment: new FormControl('', Validators.maxLength(100))
  }, this.validateTimeField)

  async loadCategories() {
    try {
      const result = await firstValueFrom(this._http.get<any>('api/time/category'));
      this.categoryList = result;
      this.categoryFilter.next(this.sortGroups(this.categoryList.slice()));
    } catch (error) {

    }
  }

  sortGroups(input: any[]) {
    const sorted = [];
    input.forEach(category => {
      const index = sorted.findIndex(g => g.name === category.group);
      if (index === -1) {
        sorted.push({ name: category.group, data: [category] });
      } else {
        sorted[index].data.push(category);
      }
    })
    return sorted;
  }

  validateTimeField(c: AbstractControl): ValidationErrors {
    const inField = c.get('in'),
      outField = c.get('out');

    if (!inField.value || !outField.value) {
      return null;
    }
    const inTime = dayjs(inField.value, 'HH:mm'),
      outTime = dayjs(outField.value, 'HH:mm');

    if (inTime.isAfter(outTime, 'm')) {
      inField.setErrors({ smallerTime: true });
      outField.setErrors({ smallerTime: true });
      return {
        smallerTime: true,
      };
    }
    if (inTime.isSame(outTime, 'm')) {
      inField.setErrors({ sameTime: true });
      outField.setErrors({ sameTime: true });
      return {
        sameTime: true,
      };
    }
    return null;
  }

  async save() {
    if (this.group.invalid) return;

    const payload: any = this.group.value;
    if (this.timeId) {
      payload.id = this.timeId;
    }
    payload.date = dayjs(payload.date).format('YYYY-MM-DD') as any;
    this.isLoading = true;
    this.group.disable();

    try {
      if (this.adminEdit) {
        await firstValueFrom(this._http.post(`api/admin/time/${this.timeId}`, payload));
      } else {
        await firstValueFrom(this._http.post(`api/time`, payload));
      }
      this._ref.close(true);
      if ( this.timeId) {
        this._config.showSnackbar('Buchung erfolgreich aktualisiert!', 3000, 'success');
      } else this._config.showSnackbar('Buchung erfolgreich hinzugefügt!', 3000, 'success');
    } catch (e) {
      this.isLoading = false;
      this.group.enable();

      if (e.error.error) {
        this._config.showSnackbar(e.error.error, 3000, 'error')
        return;
      }
      this._config.showSnackbar('Buchung konnte nicht hinzugefügt werden!', 3000, 'error')
    }
  }

  protected filterCategories() {
    if (!this.categoryList) {
      return;
    }
    let search = this.categoryFilterCtrl.value;
    if (!search) {
      this.categoryFilter.next(this.sortGroups(this.categoryList.slice()));
      return;
    } else {
      search = search.toLowerCase();
    }
    this.categoryFilter.next(
      this.sortGroups(this.categoryList.filter(category => category.name.indexOf(search) !== -1))
    );
  }

}
