import { HttpClient } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateAdapter } from 'angular-calendar';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { ConfigModule } from 'src/app/util/config';
import { CustomDateAdapterService } from 'src/app/util/customDateAdapter';
import { CreateTimeTrackingDialogComponent } from 'src/app/util/dialogs/create-time-tracking-dialog/create-time-tracking-dialog.component';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';

@Component({
  selector: 'app-time-tracking-admin',
  templateUrl: './time-tracking-admin.component.html',
  styleUrls: ['./time-tracking-admin.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapterService,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: { display: { dateInput: 'monthYearLabel' } } },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
  ]
})
export class TimeTrackingAdminComponent {

  @ViewChild('timeAdminMenu', { static: false }) menuSheet: TemplateRef<any>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  max = new Date();
  dayjs = dayjs;
  dataSource = new MatTableDataSource<any>();
  showLoader = true;
  
  tableSearch = new FormControl('');

  rawData = [];
  displayedColumns = ['category', 'comment', 'date', 'timeframe', 'user', 'status', 'action']

  constructor(private _dialog: MatDialog,
    private _http: HttpClient,
    private _bottomSheet: MatBottomSheet,
    private _config: ConfigModule
  ) { }

  ngOnInit(): void {
    this.loadTimes();

    this.tableSearch.valueChanges.subscribe(text => {
      this.dataSource.filter = text;
    });
    this._config.setTitle('Zeiterfassung');
  }

  openMenu() {
    this._bottomSheet.open(this.menuSheet);
  }

  async loadTimes() {
    this.showLoader = true;
    try {
      const result = await firstValueFrom(this._http.get<any>(`api/admin/time`));
      this.rawData = result.data;
      this.dataSource.data = this.rawData;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } catch (error) {
      if (error?.error?.error) {
        this._config.showSnackbar(error.error.error, 3000, 'error');
        return;
      }
      this._config.showSnackbar('Konnte Buchungen nicht abrufen!', 3000, 'error');
    } finally {
      this.showLoader = false;
    }
  }

  async editEntry(item: any) {
    const result = await firstValueFrom(this._dialog.open(CreateTimeTrackingDialogComponent, {
      width: '500px',
      data: {
        item,
        admin: true
      }
    }).afterClosed());

    if (result) {
      this.loadTimes();
    }
  }

  async deleteEntry(id: number) {
    this.showLoader = true;
    try {
      await firstValueFrom(this._http.delete(`api/admin/time/${id}`));
      this.loadTimes();
      this._config.showSnackbar('Buchung wurde erfolgreich gelöscht!', 3000, 'error');
    } catch (error) {
      if (error?.error?.error) {
        this._config.showSnackbar(error.error.error, 3000, 'error');
        return;
      }
      this._config.showSnackbar('Fehler beim Löschen der Buchung!', 3000, 'error');
    } finally {
      this.showLoader = false;
    }
  }

  async verifyEntry(id: number) {
    this.showLoader = true;
    try {
      await firstValueFrom(this._http.post(`api/admin/time/${id}/verify`, {}));
      this.loadTimes();
      this._config.showSnackbar('Buchung wurde erfolgreich bestätigt!', 3000, 'error');
    } catch (error) {
      if (error?.error?.error) {
        this._config.showSnackbar(error.error.error, 3000, 'error');
        return;
      }
      this._config.showSnackbar('Fehler beim Löschen der Buchung!', 3000, 'error');
    } finally {
      this.showLoader = false;
    }
  }

  changeListView(event) {
    let data;
    if (event.checked) {
      data = this.rawData.filter(element => element.verified == 0);
    } else {
      data = this.rawData;
    }
    this.dataSource.data = data;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
