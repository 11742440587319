<mat-dialog-content>
  <form [formGroup]="group">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Kategorie Name</mat-label>
      <input matInput formControlName="category" />
    </mat-form-field>

    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Kategorie Icon</mat-label>
      <input matInput formControlName="icon" readonly="true" (click)="openIconPicker()" />
      <mat-icon matSuffix (click)="openIconPicker()">{{ _data?.item?.icon }}</mat-icon>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
  <button mat-raised-button color="warn" *ngIf="_data?.item?.id" (click)="deleteItem()">
    <mat-icon>delete</mat-icon> Löschen
  </button>
  <button mat-raised-button color="success" (click)="saveItem()" [disabled]="group.invalid">
    Speichern <mat-icon>save</mat-icon>
  </button>
</mat-dialog-actions>