import { NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';
@NgModule({
  imports: [],
  exports: [],
  providers: [{ provide: 'BASE_URL', useFactory: getHostUrl }],
})
export class ConfigModule {
  constructor(private _titleService: Title,
    private _snackBar: MatSnackBar) { }

  private updating: boolean = false;

  public setTitle(title: string) {
    this._titleService.setTitle(title);
    environment.pageTitle = title;
  }

  public showSnackbar(message, duration = 30000, icon: SweetAlertIcon = 'info', position: SweetAlertPosition = 'top-right') {
    Swal.fire({
      toast: true,
      text: message,
      icon: icon,
      position: position,
      showConfirmButton: false,
      timer: duration,
      timerProgressBar: true,
      color: '#000'
    })
  }

  public startUpdate(): void {
    if (!this.updating) {
      this.updating = true;
      const snackbar = this._snackBar.open('neue Version verfügbar', 'Aktualisieren', {
        panelClass: 'warning',
        duration: 10000,
      });
      snackbar.onAction().subscribe(() => {
        caches.keys().then(names => {
          for (let name of names)
            caches.delete(name);
        }).finally(() => {
          window.location.reload();
        });
      });
    }
  }
}

export function getHostUrl() {
  if (environment.debugging) {
    return 'http://localhost/api/'
  }
  return `${window.location.protocol}//${window.location.hostname}/api/`;
}

export function getHost() {
  if (environment.debugging) {
    return 'http://localhost/'
  }
  return `${window.location.origin}/`;
}

export function getHostname() {
  if (environment.debugging) {
    return 'localhost/'
  }
  return `${window.location.hostname}/`;
}

export function getColorForSeriesName(name: string): string | null {
  switch (name) {
    case 'Brandeinsatz': return '#da1212';
    case 'Technische Hilfeleistung': return '#2e71a8';
    case 'Verkehrsunfall': return '#ff7b00';
    case 'First Responder': return '#ff0';
    case 'Unwetter': return '#00a032';
    case 'Sicherheitswachdienst': return '#5a5a5a';
  }
  return null;
}

export const alarmTypes: string[] = ['Brandeinsatz', 'Technische Hilfeleistung', 'Verkehrsunfall', 'First Responder', 'Unwetter', 'Sicherheitswachdienst'];

export function formatDayjs(value: string, format: string): string {
  dayjs.extend(utc);
  return dayjs.utc(value).local().format(format);
}

const umlautMap = {
  '\u00dc': 'UE',
  '\u00c4': 'AE',
  '\u00d6': 'OE',
  '\u00fc': 'ue',
  '\u00e4': 'ae',
  '\u00f6': 'oe',
  '\u00df': 'ss',
  '\u0020': ' '
}

export function replaceUmlaute(str: string): string {
  return str.replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', "g"),
    (a) => {
      console.log(a + ' => ' + umlautMap[a]);
      return umlautMap[a];
    }
  );
}