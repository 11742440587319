import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Login } from '../../util/login';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { ConfigModule } from 'src/app/util/config';
import { CreateTimeTrackingDialogComponent } from 'src/app/util/dialogs/create-time-tracking-dialog/create-time-tracking-dialog.component';
import { TimeTrackingDayListComponent } from './time-tracking-day-list/time-tracking-day-list.component';

@Component({
  selector: 'app-time-tracking',
  templateUrl: './time-tracking.component.html',
  styleUrls: ['./time-tracking.component.scss']
})
export class TimeTrackingComponent implements OnInit {

  @ViewChild('timeMenu', { static: false }) menuSheet: TemplateRef<any>;
  @ViewChild('dayList') dayList: TimeTrackingDayListComponent;
  @ViewChild('monthList') monthList: TimeTrackingDayListComponent;

  currentDate = new FormControl(new Date());
  dayjs = dayjs;
  viewMode: 'day' | 'month' = 'day';
  
  constructor(private _dialog: MatDialog,
    private _http: HttpClient,
    private _bottomSheet: MatBottomSheet,
    public _login: Login,
    private _config: ConfigModule,
    private _router: Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._config.setTitle('Zeiterfassung');
    this.viewMode = this._route.snapshot.params.view ?? 'day';
  }

  openMenu() {
    this._bottomSheet.open(this.menuSheet);
  }

  switchView() {
    this.viewMode === 'day' ? this.viewMode = 'month' : this.viewMode = 'day';
    this._router.navigate([`/timetracking/${this.viewMode}`]);
  }

  async createTime() {    
    const result = await firstValueFrom(this._dialog.open(CreateTimeTrackingDialogComponent, {
      width: '500px',
      data: {
        date: this.currentDate.value
      }
    }).afterClosed());

    if (result) {
      this.dayList.loadTimes();
      this.monthList.loadTimes();
    }
  }
}
